import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import config from 'config';
import { setHeaders } from 'services/apiHeaders';
import { CustomError } from 'types/CustomErrorTypes';

export const dataSlice = createApi({
  reducerPath: 'dataSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: config.TRIMBLE_INSIGHTS_PROJECTS_SERVICE.BASE_URL,
    prepareHeaders(headers) {
      return setHeaders(headers);
    },
  }) as BaseQueryFn<string | FetchArgs, unknown, CustomError, {}>,
  refetchOnMountOrArgChange: true,
  tagTypes: ['Data'],
  endpoints: (builder) => ({
    getDataFiles: builder.query<
      any,
      {
        pageSize: number;
        lastEvaluatedKey: string;
        startDate: string;
        endDate: string;
        id: string;
        region: string;
      }
    >({
      query: (arg) => {
        let { pageSize, lastEvaluatedKey, startDate, endDate, id, region } =
          arg;
        region = region.toLocaleLowerCase();
        let isPaginated = true;
        const params: {
          startDate: string;
          endDate: string;
          pageSize: number;
          isPaginated: boolean;
          lastEvaluatedKey?: string;
          region?: string;
          [key: string]: string | number | boolean | object | undefined;
        } = {
          startDate,
          endDate,
          pageSize,
          isPaginated,
          ...(lastEvaluatedKey && { lastEvaluatedKey }),
          ...(region && region !== 'non-eu' && { region }),
        };
        return {
          url: `projects/${id}/files`,
          params,
        };
      },
      providesTags: ['Data'],
      transformResponse: (data: any) => {
        let returnedArr = data.filesList;
        returnedArr.map((item: any) => {
          item.fileName = item.file_name;
          item.creationTime = item.created;
          return item;
        });
        return { returnedArr, lastEvaluatedKey: data.lastEvaluatedKey };
      },
    }),
  }),
});

export const { useGetDataFilesQuery } = dataSlice;

import { Breadcrumb, Chip } from '@trimbleinc/modus-react-bootstrap';
import routerPath from 'config/routerPath';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

export const Documentation = () => {
  let navigate = useNavigate();
  const [versionSelected, setVersionSelected] = useState('v1');
  return (
    <>
      <div className="row ">
        <div className="container-fluid">
          <Breadcrumb className="breadcrumbCustom mt-1 ml-3">
            <Breadcrumb.Item
              onClick={() => {
                navigate(routerPath.PRIVATE_ROUTE.projectsHomePath);
              }}
            >
              Trimble Insights Portal
            </Breadcrumb.Item>
            <Breadcrumb.Item active>API Docs</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div>
          <div className="container-fluid ml-3">
            <Chip
              icon={
                versionSelected == 'v1' ? (
                  <i className="material-icons">check</i>
                ) : (
                  <></>
                )
              }
              label="V1"
              variant={versionSelected == 'v1' ? 'solid' : 'outline'}
              type="filter"
              className="mr-2"
              onClick={() => setVersionSelected('v1')}
            ></Chip>
            <Chip
              icon={
                versionSelected == 'legacy' ? (
                  <i className="material-icons">check</i>
                ) : (
                  <></>
                )
              }
              label="Legacy"
              variant={versionSelected == 'legacy' ? 'solid' : 'outline'}
              type="filter"
              onClick={() => setVersionSelected('legacy')}
            ></Chip>
          </div>
        </div>
      </div>
      {versionSelected == 'v1' && (
        <SwaggerUI url={'./trimble-insights-api-documentation.yaml'} />
      )}
      {versionSelected == 'legacy' && (
        <SwaggerUI url={'./trimble-insights-api-legacy.yaml'} />
      )}
    </>
  );
};

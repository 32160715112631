const getPath = {
  AUTH_API_SERVICE_URL: {
    redirect: "redirect",
    oauthToken: "oauth/token",
    logout: "logout",
  },
  TRIMBLE_INSIGHTS_PROJECTS_SERVICE_URL: {
    projects: "projects",
    revoke: "Oauth/revoke",
    projectStatus: "projects/{id}/status",
    users: "projects/{id}/Users",
    projectDetails: "projects/{id}",
    userQuota: "users",
  },
};

export default getPath;

import { FormRadioButton } from 'components/common/FormRadioButton';
import React from 'react';
import { CloseButton } from '@trimbleinc/modus-react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';
import { UserRoleOption } from 'types/enum/UserEnum';
import {
  useAddUserMutation,
  useDeleteUserMutation,
} from 'services/users/userSlice';
import { messages } from 'components/common/messages';
import { MessageSeverity } from 'types/enum/MessageSeverity';
import { UserTypes } from 'types/UserTypes';
import { CenteredSpinnerCircle } from 'components/common/CenteredSpinnerCircle';
import { ConfirmationDialog } from 'components/common/ConfirmationDialog';
import { sendCustomLog } from 'helpers/sdkCustomEventLogger';
import CustomTable from 'components/common/CustomTable';
import { checkValidEmailDomain } from 'common/commonFunction';
interface usersListTypes {
  isFetching: boolean;
  userMode: boolean;
  currentUser: string;
  projectId: string;
  handleSetToastProps: (message: string, severity: string) => void;
  data: UserTypes[];
  handleFetch: (value: boolean) => void;
}
const Styles = {
  Close: {
    fontSize: '28px',
  },
};
export const UsersList = (props: usersListTypes) => {
  const [updateUser, updateUserData] = useAddUserMutation();
  const [deleteUser, deleteUserData] = useDeleteUserMutation();
  const [deleteUserEmail, setDeleteUserEmail] = React.useState('');
  const columns = props.userMode
    ? [
        {
          dataField: 'email',
          text: 'User Email Id',
        },
        {
          dataField: 'admin',
          text: 'Admin',
          style: { width: '9vh' },
        },
      ]
    : [
        {
          dataField: 'email',
          text: 'User Email Id',
        },
        {
          dataField: 'admin',
          text: 'Admin',
          style: { width: '9vh' },
        },
        {
          dataField: 'close',
          text: '',
          style: { width: '6vh' },
        },
      ];
  React.useEffect(() => {
    if (!updateUserData.isLoading) {
      if (updateUserData.isSuccess) {
        props.handleSetToastProps(
          messages.UPDATEUSERROLE_SUCCESS,
          MessageSeverity.success
        );
      } else if (updateUserData.isError) {
        props.handleSetToastProps(messages.FAILURE, MessageSeverity.error);
      }
    } else {
      props.handleFetch(true);
    }
  }, [updateUserData.isLoading, updateUserData.isSuccess]);
  React.useEffect(() => {
    if (!deleteUserData.isLoading) {
      if (deleteUserData.isSuccess) {
        props.handleSetToastProps(
          messages.DELETEUSER_SUCCESS,
          MessageSeverity.success
        );
      } else if (deleteUserData.isError) {
        props.handleSetToastProps(messages.FAILURE, MessageSeverity.error);
      }
    } else {
      props.handleFetch(true);
    }
  }, [deleteUserData.isLoading, deleteUserData.isSuccess]);
  const userDataEvent = props.data.map((user) => {
    var data = {
      email: user.email,
      admin: (
        <FormRadioButton
          className="m-0 pt-3 pb-0 pl-0 pr-0 ml-3 mb-3"
          option={[UserRoleOption.Admin]}
          label=""
          disabled={
            props.userMode ||
            user.email === props.currentUser ||
            !checkValidEmailDomain()
          }
          Id={user.email}
          key={`${user.email} ${user.role}`}
          type="checkbox"
          value={
            user.role === UserRoleOption.Admin
              ? UserRoleOption.Admin
              : undefined
          }
          removeLabel={true}
          handleInput={({ name, checked }) => {
            sendCustomLog(`UPDATE_ROLE`, 'USERS', {
              userName: name,
              updatedMode: checked ? UserRoleOption.Admin : UserRoleOption.User,
            });
            updateUser({
              projectId: props.projectId,
              email: name,
              role: checked ? UserRoleOption.Admin : UserRoleOption.User,
            });
          }}
        ></FormRadioButton>
      ),
      close: (
        <div>
          <CloseButton
            className={
              user.email === props.currentUser || !checkValidEmailDomain()
                ? 'text-muted'
                : 'text-danger'
            }
            disabled={
              user.email === props.currentUser || !checkValidEmailDomain()
            }
            style={Styles.Close}
            onClick={() => {
              setDeleteUserEmail(user.email);
            }}
          ></CloseButton>
        </div>
      ),
    };
    if (props.userMode) return { email: data.email, admin: data.admin };
    return data;
  });
  return updateUserData.isLoading ||
    deleteUserData.isLoading ||
    props.isFetching ? (
    <CenteredSpinnerCircle loadingText="Loading"></CenteredSpinnerCircle>
  ) : (
    <Container fluid>
      {deleteUserEmail && (
        <ConfirmationDialog
          dialogHeader="Delete Confirmation"
          dialogContent={`Do you want to delete ${deleteUserEmail}`}
          onNo={() => {
            setDeleteUserEmail('');
          }}
          onYes={() => {
            sendCustomLog('DELETE_EMAIL', 'USERS', {
              deletedEmail: deleteUserEmail,
              deletedBy: props.currentUser,
            });
            deleteUser({
              projectId: props.projectId,
              email: deleteUserEmail,
            });
            setDeleteUserEmail('');
          }}
        ></ConfirmationDialog>
      )}
      <Row className="m-0 pt-2 pb-2">
        <Col
          style={{
            overflow: 'auto',
          }}
          className="ml-3"
        >
          <div className="ml-n3">
            <CustomTable
              keyField="userEmail"
              columns={columns}
              data={userDataEvent}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

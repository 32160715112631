import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

export interface SpinnerCircleProps {
  loadingText: string;
}
export const SpinnerCircle = (props: SpinnerCircleProps) => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <Spinner animation="border" variant="primary" />
      <div className="h3 text-primary mt-3">{props.loadingText}...</div>
    </div>
  );
};

import React, { useEffect } from 'react';
import { Breadcrumb } from '@trimbleinc/modus-react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { AddUser } from './AddUser';
import { UsersList } from './UsersList';
import routerPath from 'config/routerPath';
import { IPropTypesRouteValidations } from 'types/RouteValidationsTypes';
import WithRouteValidation from 'components/HOC/WithRouteValidation';
import { useGetUserQuery } from 'services/users/userSlice';
import { CenteredSpinnerCircle } from 'components/common/CenteredSpinnerCircle';
import { UserRoleOption } from 'types/enum/UserEnum';
import WithToast from 'components/HOC/WithToast';
import { IPropTypesToast } from 'types/ToastTypes';
import { messages } from 'components/common/messages';
import { MessageSeverity } from 'types/enum/MessageSeverity';
import { ProjectStatusOption } from 'types/enum/ProjectEnum';
import { MAIL_ID } from 'helpers/constants';
import {
  getSessionData,
  sessionConstants,
} from 'components/storage/sessionStorage';
const Styles = {
  outerContainer: {
    height: '90vh',
  },
  btn: {
    height: '49px',
  },
  bgColor: {
    backgroundColor: '#e0e1e9',
  },
  padd: {
    paddingTop: '3px',
  },
};
export const Users = (props: IPropTypesRouteValidations & IPropTypesToast) => {
  const [userMode, setUserMode] = React.useState(true);
  const location = useLocation();
  let navigate = useNavigate();
  let projectId = location.search.split('?projectId=')[1];
  const { isFetching, isSuccess, data, isError, error, refetch } =
    useGetUserQuery(projectId);
  const email = getSessionData(sessionConstants.email);
  const [fetch, setFetch] = React.useState(false);
  const handleFetch = (value: boolean) => {
    setFetch(value);
  };
  useEffect(() => {
    if (isError && error && 'data' in error) {
      if (error.status >= 400 && error.status <= 499) {
        props.handleSetToastProps(error.data.error, MessageSeverity.error);
      }
      if (error.status >= 500) {
        props.handleSetToastProps(
          messages.INTERNAL_SERVER_ERROR,
          MessageSeverity.error
        );
      }
    }
  }, [isError]);

  useEffect(() => {
    refetch();
  }, []);
  useEffect(() => {
    if (!isFetching) {
      handleFetch(false);
      if (isSuccess) {
        if (
          data.find((user) => {
            if (user.email === email)
              if (user.role === UserRoleOption.Admin) return true;
          })
        ) {
          setUserMode(false);
        } else {
          setUserMode(true);
        }
      } else if (isError) {
        props.handleSetToastProps(messages.FAILURE, MessageSeverity.error);
      }
    }
  }, [isFetching]);
  return (
    <div>
      {isFetching && (
        <CenteredSpinnerCircle loadingText="Loading"></CenteredSpinnerCircle>
      )}
      <Container fluid style={Styles.outerContainer}>
        <Row>
          <Row className="m-0 pt-2 pb-2">
            <Breadcrumb className="breadcrumb-underline breadcrumbCustom">
              <Breadcrumb.Item
                onClick={() => {
                  navigate(routerPath.PRIVATE_ROUTE.projectsHomePath);
                }}
              >
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item
                onClick={() => {
                  navigate(
                    `/${routerPath.PRIVATE_ROUTE.projectPath}/${projectId}`
                  );
                }}
              >
                {props.projectName}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Users</Breadcrumb.Item>
            </Breadcrumb>
          </Row>
          <Col>
            <h1 className="h1 font-weight-bold mt-n2 col-md-2 col-xs-12">
              Users
            </h1>
          </Col>
        </Row>
        {isSuccess ? (
          props.projectStatus &&
          props.projectStatus !== ProjectStatusOption.SUCCEEDED &&
          !isFetching ? (
            <Row>
              <div className="col-12 d-flex justify-content-center nullFilesCustom">
                <div className="container d-flex justify-content-center align-items-center">
                  <span className="h2 font-weight-light">
                    The project has not yet been setup in Trimble Insights.
                    <br />
                    Please contact <a href={`mailto:${MAIL_ID}`}>{MAIL_ID}</a>
                  </span>
                </div>
              </div>
            </Row>
          ) : (
            <>
              <Row>
                <AddUser
                  handleSetToastProps={props.handleSetToastProps}
                  userMode={userMode}
                  projectId={projectId}
                  data={data}
                  isFetching={isFetching || fetch}
                  handleFetch={handleFetch}
                ></AddUser>
              </Row>
              <Row>
                <UsersList
                  currentUser={email!}
                  handleSetToastProps={props.handleSetToastProps}
                  projectId={projectId}
                  userMode={userMode}
                  data={data}
                  isFetching={isFetching || fetch}
                  handleFetch={handleFetch}
                ></UsersList>
              </Row>
            </>
          )
        ) : (
          <div></div>
        )}
      </Container>
    </div>
  );
};

const UsersWithRouteValidation = WithRouteValidation(Users);
const UsersWithToast = WithToast(UsersWithRouteValidation);
export default UsersWithToast;

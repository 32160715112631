export function getDefaultStartDate() {
  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 15);
  return (
    currentDate.getFullYear() +
    '-' +
    ('0' + (currentDate.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + currentDate.getDate()).slice(-2)
  );
}
export function getDefaultEndDate() {
  let newDate = new Date();
  return (
    newDate.getFullYear() +
    '-' +
    ('0' + (newDate.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + newDate.getDate()).slice(-2)
  );
}
export function formattedDate(d) {
  let date;
  if (typeof d == 'string') {
    date = new Date(d);
  } else {
    date = d;
  }
  return (
    date.getFullYear() +
    '-' +
    date.getMonth() +
    1 +
    '-' +
    date.getDate()
  ).toString();
}

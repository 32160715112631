export const messages = {
  PROJECTDETAILS_SUCCESS: 'Project Created SuccessFully',
  PROJECTDETAILS_ERROR: 'Something went wrong',
  FAILURE: 'Something went wrong',
  LOGIN_FAILURE: 'Login Failed',
  DOWNLOAD_FAILURE: 'Download Failed',
  INVALID_DATE_RANGE: 'Start Date should be before the End Date',
  INVALID_START_DATE: 'Start Date cannot be a date beyond Today',
  COPY_CONSUMER_KEY: 'Copied Consumer Key',
  COPY_CONSUMER_SECRET: 'Copied Consumer Secret',
  MISSING_START_DATE: 'Start Date is required',
  MISSING_END_DATE: 'End Date is required',
  EXECUTION_CREATION_IN_PROGRESS: 'An execution is already in progress',
  MAX_FILES_SELECTION_LIMIT: 'Cannot select more than 10 files',
  ADDUSER_SUCCESS: 'User added successfully',
  DELETEUSER_SUCCESS: 'User deleted successfully',
  DELETION_INPROGRESS: 'This project is being deleted',
  DELETION_FAILED:
    'The deletion process of this project is failed, Please contact the Administrator',
  UPDATEUSERROLE_SUCCESS: 'User role updated successfully',
  USER_ALREADY_EXIST: 'User already exist',
  INTERNAL_SERVER_ERROR: 'Internal Server Error',
  CONFIG_SAVE_SUCCESS: 'Saved Successfully',
  CONFIG_SAVE_ERROR: 'Saving Failed',
  COUNT_TRIGGER_POINT_ERROR:
    'For Rule Type Count, Trigger Point has to be greater than 0',
  DURATION_TRIGGER_POINT_ERROR:
    'For Rule Type Duration, Trigger Point has to be greater than 0',
  QUOTA_OVER:
    'Your project creation limit is exceeded, Please contact the administrator',
  QUOTA_ERROR: 'Invalid user',
  EXECUTIONS_LIMIT_EXCEEDED:
    'Daily limit for the number of executions has been exceeded',
  INVALID_EMAIL_DOMAIN:
    'Your email is not recognized as a valid Trimble-approved user',
};

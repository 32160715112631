import 'styles/Configurations.css';
import WithRouteValidation from 'components/HOC/WithRouteValidation';
import WithToast from 'components/HOC/WithToast';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { IPropTypesRouteValidations } from 'types/RouteValidationsTypes';
import { IPropTypesToast } from 'types/ToastTypes';

import {
  useCreateConfigurationMutation,
  useGetConfigurationQuery,
} from 'services/configuration/configurationSlice';
import { CenteredSpinnerCircle } from 'components/common/CenteredSpinnerCircle';
import { MessageSeverity } from 'types/enum/MessageSeverity';
import { messages } from 'components/common/messages';
import { ConfigurationsHeader } from './ConfigurationsHeader';
import { ConfigLoaderText } from 'types/enum/ConfigurationsLoaderTextEnum';
import { ConfigurationsModal } from './ConfigurationsModal';
import { LeftPanel } from './LeftPanel';
import { INPSFormValues } from 'types/NPSFormValueTypes';
import { IPIIFormValues } from 'types/PIIFormValueTypes';
import { RightPanel } from './RightPanel';
import { RuleType } from 'types/enum/NPSEnum';
import { sendCustomLog } from 'helpers/sdkCustomEventLogger';

const ProjectConfigurations = (
  props: IPropTypesToast & IPropTypesRouteValidations
) => {
  let location = useLocation();
  let searchParamProjectId = location.search.split('?projectId=')[1];
  let projectName = props?.projectName
    ? props.projectName
    : 'Project Does Not Exist';
  const [invalidModalName, setInvalidModalName] = useState('');
  const [invalidModalMsg, setInvalidModalMsg] = useState('');
  const handleInvalidModalName = (arg: string) => {
    setInvalidModalName(arg);
  };
  const handleInvalidModalMsg = (arg: string) => {
    setInvalidModalMsg(arg);
  };
  const [showNPS, setShowNPS] = useState(true);
  const handleShowNPS = () => {
    setShowNPS(!showNPS);
  };
  const [NPSEnabledValue, setNPSEnabledValue] = useState(false);
  const [NPSFormValues, setNPSFormValues] = useState([
    {
      eventName: '',
      ruleType: 'count',
      ruleCondition: 'once every',
      triggerPoint: '1',
    },
  ]);
  const [PIIFormValues, setPIIFormValues] = useState([
    {
      attributeName: '',
      pii_action: 'hash',
    },
  ]);
  const handleNPSFormValues = (idx: number, keyName: string, value: string) => {
    let tempData = [...NPSFormValues];
    let tempObj = Object.assign({}, tempData[idx]);
    tempObj[keyName as keyof INPSFormValues] = value;
    if (keyName == 'ruleType' && value == RuleType.DURATIONINDAYS) {
      tempObj['triggerPoint'] = parseInt(tempObj['triggerPoint']) + 'D';
    } else if (keyName == 'ruleType' && value == RuleType.COUNT) {
      tempObj['triggerPoint'] = parseInt(tempObj['triggerPoint']) + '';
    }
    tempData[idx] = tempObj;
    setNPSFormValues(tempData);
  };
  const handlePIIFormValues = (idx: number, keyName: string, value: string) => {
    let tempData = [...PIIFormValues];
    let tempObj = Object.assign({}, tempData[idx]);
    tempObj[keyName as keyof IPIIFormValues] = value;
    tempData[idx] = tempObj;
    setPIIFormValues(tempData);
  };
  const [showPII, setShowPII] = useState(false);
  const handleShowPII = () => {
    setShowPII(!showPII);
  };
  const handleNPSSwitch = () => {
    setNPSEnabledValue(!NPSEnabledValue);
  };
  const addFieldsNPS = (NPSFieldObj: INPSFormValues) => {
    setNPSFormValues([...NPSFormValues, NPSFieldObj]);
  };
  const addFieldsPII = (PIIFieldObj: IPIIFormValues) => {
    setPIIFormValues([...PIIFormValues, PIIFieldObj]);
  };
  const removeFieldsNPS = (index: number) => {
    let tempData = [...NPSFormValues];
    sendCustomLog('DELETE_NPS', 'CONFIGURATIONS', {
      configurationName: NPSFormValues[index].eventName,
    });
    tempData.splice(index, 1);
    setNPSFormValues(tempData);
  };
  const removeFieldsPII = (index: number) => {
    let tempData = [...PIIFormValues];
    sendCustomLog('DELETE_PII', 'CONFIGURATIONS', {
      configurationName: PIIFormValues[index].attributeName,
    });
    tempData.splice(index, 1);
    setPIIFormValues(tempData);
  };

  let showComponents = showNPS || showPII;
  // 1. API call for getting the config.
  const {
    data: getConfigData,
    isLoading: isGetConfigLoading,
    isFetching: isGetConfigFetching,
    isSuccess: isGetConfigSuccess,
    error: getConfigError,
    isError: isGetConfigError,
  } = useGetConfigurationQuery(
    {
      id: searchParamProjectId,
      configType: showNPS ? 'nps' : 'pii',
    },
    { skip: showComponents ? false : true }
  );
  useEffect(() => {
    if (isGetConfigSuccess && !isGetConfigFetching && !isGetConfigLoading) {
      if (showNPS && getConfigData?.nps) {
        setNPSEnabledValue(getConfigData.nps.isNPSEnabled);
        setNPSFormValues(getConfigData.nps.rules);
      }
      if (showPII && getConfigData?.pii?.attributes) {
        setPIIFormValues(getConfigData.pii.attributes);
      }
    }
  }, [isGetConfigLoading, isGetConfigFetching, isGetConfigSuccess]);

  // 2. API call for creation of a new configuration.
  const [
    createConfiguration,
    {
      data: configCreationData,
      isSuccess: isConfigCreationSuccess,
      isLoading: isLoadingConfigCreation,
      isError: isConfigCreationError,
      error: configCreationError,
    },
  ] = useCreateConfigurationMutation();

  useEffect(() => {
    //config creation success
    if (
      isConfigCreationSuccess == true &&
      isConfigCreationError == false &&
      isLoadingConfigCreation == false
    ) {
      if (
        (showNPS && configCreationData?.nps) ||
        (showPII && configCreationData?.pii)
      ) {
        props.handleSetToastProps(
          messages.CONFIG_SAVE_SUCCESS,
          MessageSeverity.success
        );
      }
    }
    //create config : error handling
    if (
      isConfigCreationError &&
      configCreationError &&
      'data' in configCreationError
    ) {
      if (
        configCreationError.status >= 400 &&
        configCreationError.status <= 499
      ) {
        props.handleSetToastProps(
          configCreationError.data.error,
          MessageSeverity.error
        );
      } else if (configCreationError.status >= 500) {
        props.handleSetToastProps(
          configCreationError.data.error,
          MessageSeverity.warning
        );
      } else {
        props.handleSetToastProps(
          messages.CONFIG_SAVE_ERROR,
          MessageSeverity.error
        );
      }
    }
    //get config error : handling
    if (isGetConfigError && getConfigError && 'data' in getConfigError) {
      if (getConfigError.status >= 400 && getConfigError.status <= 499) {
        props.handleSetToastProps(
          getConfigError.data.error,
          MessageSeverity.error
        );
      }
      if (getConfigError.status >= 500) {
        props.handleSetToastProps(
          messages.INTERNAL_SERVER_ERROR,
          MessageSeverity.error
        );
      }
    }
  }, [isGetConfigError, isConfigCreationSuccess, isConfigCreationError]);

  const renderSpinner = (arg?: string) => {
    if (arg === ConfigLoaderText.LOADER_FOR_PUT) {
      return (
        <CenteredSpinnerCircle
          loadingText={`${
            showNPS
              ? NPSEnabledValue == false || getConfigData.nps.rules.length != 0
                ? 'Updating NPS'
                : 'Creating NPS'
              : getConfigData.pii.attributes.length != 0
              ? 'Updating PII'
              : 'Creating PII'
          } Configuration`}
        />
      );
    }
    if (arg === ConfigLoaderText.LOADER_FOR_GET) {
      return (
        <CenteredSpinnerCircle
          loadingText={`Loading ${
            showNPS ? 'NPS' : 'PII'
          } Configuration Details`}
        />
      );
    }
  };
  const [submit, setSubmit] = useState(false);
  const handleSubmit = (arg: boolean) => {
    setSubmit(arg);
  };
  useEffect(() => {
    if (submit) {
      if (showNPS) {
        let npsRules = NPSFormValues;
        if (NPSEnabledValue == true && npsRules.length == 0) {
          handleInvalidModalName('NPS');
          handleInvalidModalMsg(
            'NPS is Enabled but no NPS configurations is defined.'
          );
          handleSubmit(false);
          return;
        } else {
          let npsObj = {
            isNPSEnabled: NPSEnabledValue,
            rules: npsRules,
          };
          let result = {
            nps: NPSEnabledValue
              ? npsObj
              : { isNPSEnabled: NPSEnabledValue, rules: [] },
          };
          if (invalidModalName != 'NPS') {
            createConfiguration({
              id: searchParamProjectId,
              configType: 'nps',
              body: result,
            });
          }
          handleSubmit(false);
        }
      }
      if (showPII) {
        let piiAttributes = PIIFormValues;
        if (piiAttributes.length == 0) {
          handleInvalidModalName('PII');
          handleInvalidModalMsg('No PII configurations is defined.');
          handleSubmit(false);
          return;
        } else {
          let piiObj = { attributes: piiAttributes };
          let result = {
            pii: piiObj,
          };
          if (invalidModalName != 'PII') {
            createConfiguration({
              id: searchParamProjectId,
              configType: 'pii',
              body: result,
            });
          }
          handleSubmit(false);
        }
      }
    }
  }, [submit]);

  return (
    <div className="container col-12">
      <div className="container-fluid mt-1">
        <ConfigurationsHeader 
        showComponents={showComponents}
        showNPS={showNPS}
        projectName={projectName} 
        showPII={showPII}
        NPSEnabledValue={NPSEnabledValue}
        NPSFormValues={NPSFormValues}
        PIIFormValues={PIIFormValues}
        handleInvalidModalName={handleInvalidModalName}
        handleSubmit={handleSubmit}
        />
        <div className="row MainPanel" style={{maxHeight:"50vh"}}>
          {
            <LeftPanel
              showNPS={showNPS}
              showPII={showPII}
              handleShowNPS={handleShowNPS}
              handleShowPII={handleShowPII}
              NPSEnabledValue={NPSEnabledValue}
              handleNPSSwitch={handleNPSSwitch}
            />
          }
          {isLoadingConfigCreation &&
            renderSpinner(ConfigLoaderText.LOADER_FOR_PUT)}
          {isGetConfigLoading || isGetConfigFetching ? (
            renderSpinner(ConfigLoaderText.LOADER_FOR_GET)
          ) : (
            <RightPanel
              showComponents={showComponents}
              showNPS={showNPS}
              showPII={showPII}
              NPSEnabledValue={NPSEnabledValue}
              NPSFormValues={NPSFormValues}
              PIIFormValues={PIIFormValues}
              handleInvalidModalName={handleInvalidModalName}
              handleNPSSwitch={handleNPSSwitch}
              handleSubmit={handleSubmit}
              handleNPSFormValues={handleNPSFormValues}
              handlePIIFormValues={handlePIIFormValues}
              removeFieldsNPS={removeFieldsNPS}
              removeFieldsPII={removeFieldsPII}
              addFieldsNPS={addFieldsNPS}
              addFieldsPII={addFieldsPII}
              handleSetToastProps={props.handleSetToastProps}
            />
          )}
        </div>
      </div>
      {/* rendering a message based Modal in case of invalid fields */}
      {invalidModalName && (
        <ConfigurationsModal
          invalidModalMsg={invalidModalMsg}
          invalidModalName={invalidModalName}
          showNPS={showNPS}
          showPII={showPII}
          handleInvalidModalMsg={handleInvalidModalMsg}
          handleInvalidModalName={handleInvalidModalName}
        />
      )}
    </div>
  );
};

const ProjectConfigurationsWithToast = WithToast(ProjectConfigurations);
const ProjectConfigurationsWithToastWithRouteValidation = WithRouteValidation(
  ProjectConfigurationsWithToast
);
export default ProjectConfigurationsWithToastWithRouteValidation;

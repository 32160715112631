import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from 'components/oauth/App';
import '@trimbleinc/modus-bootstrap/dist/modus.min.css';
import InitLogger from 'InitLogger';
const root = ReactDOM.createRoot(document.getElementById('root') as Element);

root.render(
  <React.StrictMode>
    {sessionStorage.getItem('id.token') && <InitLogger />}
    <App />
  </React.StrictMode>
);

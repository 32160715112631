import configLocal from 'config/config.local.json';
import configDev from 'config/config.dev.json';
import configProd from 'config/config.prod.json';
import configStg from 'config/config.stg.json';

const getConfig = (): any => {
  const environment = process.env.REACT_APP_ENV;
  switch (environment) {
    case 'dev':
      return configDev;
    case 'prod':
      return configProd;
    case 'stg':
      return configStg;
    default:
      return configLocal;
  }
};

export default getConfig();

import React from 'react';
import { MessageSeverity } from 'types/enum/MessageSeverity';
import { clearSessionData } from 'components/storage/sessionStorage';
import { AlertBox } from 'components/common/AlertBox';
import { messages } from 'components/common/messages';

export const Error = () => {
  clearSessionData();
  return (
    <div className="m-4">
      <AlertBox
        alert={{
          message: messages.LOGIN_FAILURE,
          severity: MessageSeverity.error,
        }}
        dismissble={false}
      ></AlertBox>
    </div>
  );
};

import {
  getSessionData,
  sessionConstants,
} from 'components/storage/sessionStorage';
import jwt from 'jwt-decode';

interface userJwt {
  given_name: string;
  family_name: string;
  email: string;
}

export const generateRandomString = (length: number): string => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const addSeconds = (numOfSeconds: number, date = new Date()): Date => {
  date.setSeconds(date.getSeconds() + numOfSeconds);
  return date;
};

export const checkValidEmailDomain = ():Boolean => {
  const idToken = getSessionData(sessionConstants.idToken);
  var user: userJwt = jwt(idToken !== null ? idToken : '');
  const email = user.email;
  const domain = email.split('@')[1];
  if (
    domain !== 'trimble.com' &&
    domain !== 'applanix.com' &&
    domain !== 'hhk.de' &&
    domain !== 'moema.tech'
  ) {
    return false;
  }
  return true;
}

export const validateExpression = (value: string, exp: RegExp): boolean => {
  return RegExp(exp).test(value);
};
export const validateAlphaNumeric = (value: string): boolean => {
  return RegExp('^[a-zA-Z0-9 ]*$').test(value);
};

export const validateAlphabets = (value: string): boolean => {
  return RegExp('^[a-zA-Z ]*$').test(value);
};

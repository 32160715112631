
import { Breadcrumb, Button } from '@trimbleinc/modus-react-bootstrap';
import routerPath from 'config/routerPath';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IPropTypesConfigurationsHeader } from 'types/ConfigurationsHeaderTypes';

export const ConfigurationsHeader = (props: IPropTypesConfigurationsHeader) => {
  const {
    showPII,
    NPSEnabledValue,
    NPSFormValues,
    PIIFormValues,
    handleInvalidModalName,
    handleSubmit,
    projectName
  } = props;
  let location = useLocation();
  let searchParamProjectId = location.search.split('?projectId=')[1];
  const navigate = useNavigate();
  return (
    <>
      <div className="row">
        <Breadcrumb className="breadcrumbCustom mt-1">
          <Breadcrumb.Item
            onClick={() => {
              navigate(routerPath.PRIVATE_ROUTE.projectsHomePath);
            }}
          >
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item
            onClick={() => {
              navigate(
                `/${routerPath.PRIVATE_ROUTE.projectPath}/${searchParamProjectId}`
              );
            }}
          >
            {projectName ? projectName : 'Project Does Not Exist'}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Configurations</Breadcrumb.Item>
          <div
          style={{position:'absolute',right:0}}
          className="save-button"
          >
          <Button
            variant="outline-primary"
            className="mb-2 mr-md-4"
            onClick={(e: any) => {
              e.preventDefault();
              if (NPSEnabledValue && !showPII) {
                //validate NPS Cards
                NPSFormValues.forEach((NPSObj) => {
                  if (
                    !(
                      NPSObj.eventName &&
                      NPSObj.ruleCondition &&
                      NPSObj.ruleType &&
                      parseInt(NPSObj.triggerPoint)
                    )
                  ) {
                    //render NPS invalid modal
                    handleInvalidModalName('NPS');
                  }
                });
              }
              if (showPII) {
                //validate PII Cards
                PIIFormValues.forEach((PIIObj) => {
                  if (!(PIIObj.attributeName && PIIObj.pii_action)) {
                    //render PII invalid modal
                    handleInvalidModalName('PII');
                  }
                });
                
              }
              handleSubmit(true);
            }}
          >
            Save
          </Button>
          </div>
        </Breadcrumb>
        
      </div>
      <div
        className="d-flex flex-md-row
flex-sm-column flex-xs-column
justify-content-between row"
      >
        <div className="h1 font-weight-bold col-md-2 col-xs-12">
          Configurations
        </div>
      </div>
    </>
  );
};

import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

import config from 'config';
import { getDuration } from 'helpers/milliSecondsConversion';
import { setHeaders } from 'services/apiHeaders';
import { CustomError } from 'types/CustomErrorTypes';

export const executionSlice = createApi({
  reducerPath: 'executionSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: config.TRIMBLE_INSIGHTS_PROJECTS_SERVICE.BASE_URL,
    prepareHeaders(headers) {
      return setHeaders(headers);
    },
  }) as BaseQueryFn<string | FetchArgs, unknown, CustomError, {}>,
  refetchOnMountOrArgChange: true,
  tagTypes: ['Executions'],
  endpoints: (builder) => ({
    getExecutionFiles: builder.query<
      any,
      {
        id: string;
      }
    >({
      query: (arg) => {
        let { id } = arg;
        return {
          url: `projects/${id}/executions`,
        };
      },
      providesTags: ['Executions'],
      transformResponse: (data: any) => {
        if (data && data.executions) {
          let formattedResponse = data.executions.map(
            (element: {
              executionId: string;
              startDate: string;
              status: string;
              stopDate?: string;
            }) => {
              return {
                executionId: element.executionId,
                startDate: new Date(element.startDate).toISOString().toString(),
                status: element.status,
                duration: element.stopDate
                  ? getDuration(element.startDate, element.stopDate)
                  : '',
              };
            }
          );
          return formattedResponse;
        }
      },
    }),
    createExecution: builder.mutation<
      any,
      {
        id: string;
      }
    >({
      query(arg) {
        let { id } = arg;
        return {
          url: `projects/${id}/executions`,
          method: 'POST',
        };
      },
      invalidatesTags: ['Executions'],
      transformResponse: (response: any) => {
        return response;
      },
    }),
  }),
});

export const { useGetExecutionFilesQuery, useCreateExecutionMutation } =
  executionSlice;

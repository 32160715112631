import { Form } from '@trimbleinc/modus-react-bootstrap';
import { sendCustomLog } from 'helpers/sdkCustomEventLogger';
import React from 'react';
import { IPropTypesConfigurationsLeftPanel } from 'types/ConfigurationsLeftPanelTypes';

const configSelectedStyle = {
  backgroundColor: '#e8f0fe',
  minWidth: '15rem',
  paddingBlock: '10px',
  cursor: 'pointer',
  display: 'flex',
};
const configUnselectedStyle = {
  paddingBlock: '10px',
  minWidth: '15rem',
  cursor: 'pointer',
  display: 'flex',
};
const configLabelStyle = {
  fontSize: '20px',
  fontWeight: 200,
  cursor: 'pointer',
};
const configLabelStyleNPS = {
  fontSize: '20px',
  fontWeight: 200,
  cursor: 'pointer',
  padding:'1px 30px 1px 17px',
};
export const LeftPanel = (props: IPropTypesConfigurationsLeftPanel) => {
  const { showNPS, showPII, handleShowNPS, handleShowPII,NPSEnabledValue,handleNPSSwitch } = props;
  return (
    <div className="col-md-3 col-12 LeftPanel d-flex"
    style={{
      overflowY: 'auto',
      maxHeight:'60px'
    }}
    >
      <div className="d-flex flex-column justify-content-between">
        <div>
          <div
            className="container-fluid mt-sm-2 mt-md-5"
            style={showNPS ? configSelectedStyle : configUnselectedStyle}
            onClick={(e) => {
              if(e.currentTarget !== e.target)  
              { return}
              if (showPII) {
                handleShowPII();
              }
              handleShowNPS();
            }}
          >
            <div onClick={() => {
              if (showPII) {
                handleShowPII();
              }
              handleShowNPS();
            }}
            style={configLabelStyleNPS}>
             NPS 
             </div>
             <div>
            <Form className="d-inline-flex justify-content-between " style={{right:"10px"}}>
              {showNPS && 
              <Form.Check
                  type="switch"
                  id="custom-switch1"
                  label={NPSEnabledValue === true ? 'Disable' : 'Enable'}
                  custom
                  onClick={(e) => {
                    if(e.currentTarget===e.target){
                      sendCustomLog('NPS_SWITCH', 'CONFIGURATIONS', {
                        isNPSEnabled: !NPSEnabledValue,
                      });
                      handleNPSSwitch();
                    }else{
                      return
                    }
                  }}
                  checked={NPSEnabledValue}
                />
              }
            </Form>
            </div>
          </div>
          <hr className="bg-dark" />
          <div
            className="container-fluid mt-sm-2 mt-md-4"
            style={showPII ? configSelectedStyle : configUnselectedStyle}
            onClick={() => {
              if (showNPS) {
                handleShowNPS();
              }
              handleShowPII();
            }}
          >
            <Form className="d-inline-flex justify-content-between">
              <Form.Label
                className="my-1 h3 text-dark mr-4 px-md-3"
                style={configLabelStyle}
              >
                <div>
                PII
                </div>
              </Form.Label>
            </Form>
          </div>
          <hr className="bg-dark" />
        </div>
      </div>
    </div>
  );
};

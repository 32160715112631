import { ToastBox } from 'components/common/ToastBox';
import React, { useEffect, useState } from 'react';
import { IPropTypesToast } from 'types/ToastTypes';

function WithToast<TProps>(
  WrappedComponent: React.FC<TProps & IPropTypesToast>
) {
  function NewComponent(props: TProps) {
    let [showToast, setShowToast] = useState(false);
    let [toastProps, setToastProps] = useState({ message: '', severity: '' });
    const handleSetToastProps = (message: string, severity: string) => {
      setToastProps({ message, severity });
    };
    useEffect(() => {
      if (toastProps.message || toastProps.severity) {
        setShowToast(true);
      } else {
        setShowToast(false);
      }
    }, [toastProps]);
    const renderToast = () => {
      return (
        <ToastBox
          alert={{
            message: toastProps.message,
            severity: toastProps.severity,
          }}
          handleClose={() => {
            setShowToast(false);
            handleSetToastProps('', '');
          }}
        ></ToastBox>
      );
    };

    return (
      <>
        {showToast && renderToast()}
        <WrappedComponent
          handleSetToastProps={handleSetToastProps}
          {...(props as TProps)}
        />
      </>
    );
  }
  return NewComponent;
}

export default WithToast;

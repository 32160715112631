import { validateExpression } from 'common/validationFunction';
import { FormDropDown } from 'components/common/FormDropDown';
import { FormField } from 'components/common/FormField';
import React from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import { useAddUserMutation } from 'services/users/userSlice';
import { UserRoleOption } from 'types/enum/UserEnum';
import { messages } from 'components/common/messages';
import { MessageSeverity } from 'types/enum/MessageSeverity';
import { UserTypes } from 'types/UserTypes';
import { sendCustomLog } from 'helpers/sdkCustomEventLogger';
import { checkValidEmailDomain } from 'common/commonFunction';
interface userDropdownTypes {
  userMode: boolean;
  isFetching: boolean;
  projectId: string;
  data: UserTypes[];
  handleSetToastProps: (message: string, severity: string) => void;
  handleFetch: (value: boolean) => void;
}
const Styles = {
  outerContainer: {
    height: '90vh',
  },
  btn: {
    height: '49px',
  },
  padd: {
    paddingTop: '3px',
  },
  disabledBtn: {
    backgroundColor: '#d3d3d3',
    height: '49px',
  },
};
export const AddUser = (props: userDropdownTypes) => {
  const [userEmail, setUserEmail] = React.useState('');
  const [userRole, setUserRole] = React.useState(`${UserRoleOption.User}`);
  const [addUser, addUserData] = useAddUserMutation();
  React.useEffect(() => {
    if (!addUserData.isLoading) {
      if (addUserData.isSuccess) {
        props.handleSetToastProps(
          messages.ADDUSER_SUCCESS,
          MessageSeverity.success
        );
        setUserEmail('');
        setUserRole(UserRoleOption.User);
      }
      if (addUserData.isError) {
        props.handleSetToastProps(messages.FAILURE, MessageSeverity.error);
      }
    } else {
      props.handleFetch(true);
    }
  }, [addUserData.isLoading, addUserData.isSuccess]);
  const handleAddUser = () => {
    if (props.data)
      if (
        !props.data.find((user) => {
          if (user.email === userEmail) {
            return true;
          }
        })
      ) {
        addUser({
          projectId: props.projectId,
          email: userEmail,
          role: userRole,
        });
      } else {
        props.handleSetToastProps(
          messages.USER_ALREADY_EXIST,
          MessageSeverity.error
        );
      }
  };
  return (
    <Container fluid>
      {!props.userMode && (
        <Row className="m-0 pt-2 pb-2">
          <Col xs="12" sm="6" md="8" lg="8" xl="9" style={Styles.padd}>
            <FormField
              controlId="userEmail"
              className="m-0 mt-n4 mb-2"
              label=""
              placeholderText="Add User Email"
              type="text"
              disabled={false}
              value={userEmail}
              handleInput={({ value }) => {
                value = value.trim();
                setUserEmail(value);
              }}
            ></FormField>
          </Col>
          <Col xs="6" sm="3" md="2" lg="2" xl="2">
            <FormDropDown
              className="m-0 mb-2"
              controlId="userRole"
              label=""
              defaultValue={userRole}
              disabled={false}
              options={Object.values(UserRoleOption)}
              value={userRole}
              handleInput={(value) => {
                setUserRole(value);
              }}
            ></FormDropDown>
          </Col>
          <Col xs="6" sm="3" md="2" lg="2" xl="1">
            <Button
              className=" rounded border-primary float-right m-0 w-100 mb-2"
              variant="light"
              style={checkValidEmailDomain() ? Styles.btn : Styles.disabledBtn}
              disabled={
                !validateExpression(
                  userEmail,
                  RegExp(
                    `^[a-zA-Z0-9.!#$%&'*+/=?^_{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$`
                  )
                ) ||
                props.isFetching ||
                addUserData.isLoading
              }
              onClick={() => {
                if (checkValidEmailDomain()) {
                  sendCustomLog('ADD_EMAIL', 'USERS', {
                    userRole: userRole.toUpperCase(),
                  });
                  handleAddUser();
                } else {
                  props.handleSetToastProps(
                    messages.INVALID_EMAIL_DOMAIN,
                    MessageSeverity.warning
                  );
                }
              }}
            >
              Add
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  );
};

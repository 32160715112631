import React from 'react';
import { Form } from 'react-bootstrap';
export interface FormTextAreaProps {
  row: number;
  value: string;
  controlId: string;
  label: string;
  error?: string;
  disabled: boolean;
  maxLength?: number;
  handleInput(id: string, value: string): void;
}

const Styles = {
  formLabel: {
    fontSize: '14px',
  },
  formContent: {
    fontSize: '16px',
  },
};

export const FormTextArea = (props: FormTextAreaProps) => {
  return (
    <div>
      <Form.Group className="mb-4" controlId={props.controlId}>
        <Form.Label className=" text-secondary" style={Styles.formLabel}>
          {props.label}
        </Form.Label>
        <Form.Control
          disabled={props.disabled}
          plaintext={props.disabled}
          style={Styles.formContent}
          required
          className={
            props.disabled
              ? props.error
                ? 'is-invalid'
                : 'font-weight-bold'
              : props.error
              ? 'is-invalid'
              : undefined
          }
          as="textarea"
          value={props.value}
          maxLength={props.maxLength}
          size="lg"
          rows={props.row}
          onInput={(event) => {
            props.handleInput(
              (event.target as HTMLInputElement).id,
              (event.target as HTMLInputElement).value
            );
          }}
        ></Form.Control>
        {props.error && (
          <Form.Text className="text-danger">{props.error}</Form.Text>
        )}
      </Form.Group>
    </div>
  );
};

import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import config from "config";
import path from "config/urlPath";
import { setHeaders } from "services/apiHeaders";
import { CustomError } from "types/CustomErrorTypes";
import { userQuotaTypes } from "types/UserQuotaTypes";
import { UserTypes } from "types/UserTypes";

export const userSlice = createApi({
  reducerPath: "userSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: config.TRIMBLE_INSIGHTS_PROJECTS_SERVICE.BASE_URL,
    prepareHeaders(headers) {
      return setHeaders(headers);
    },
  }) as BaseQueryFn<string | FetchArgs, unknown, CustomError, {}>,
  tagTypes: ["Users"],
  endpoints: (builder) => ({
    getUser: builder.query<UserTypes[], string>({
      query(data) {
        return path.TRIMBLE_INSIGHTS_PROJECTS_SERVICE_URL.users.replace(
          "{id}",
          data
        );
      },
      providesTags: ["Users"],
    }),
    addUser: builder.mutation<any, any>({
      query: (data) => ({
        url: path.TRIMBLE_INSIGHTS_PROJECTS_SERVICE_URL.users.replace(
          "{id}",
          data.projectId
        ),
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Users"],
    }),
    deleteUser: builder.mutation<any, any>({
      query: (data) => ({
        url: path.TRIMBLE_INSIGHTS_PROJECTS_SERVICE_URL.users.replace(
          "{id}",
          data.projectId
        ),
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["Users"],
    }),
    getUserQuota: builder.query<userQuotaTypes, void>({
      query() {
        return path.TRIMBLE_INSIGHTS_PROJECTS_SERVICE_URL.userQuota;
      },
      providesTags: ["Users"],
    }),
  }),
});

export const {
  useGetUserQuery,
  useAddUserMutation,
  useDeleteUserMutation,
  useGetUserQuotaQuery,
} = userSlice;

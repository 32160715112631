import React from 'react';

export const HelpCarousel = () => {
  return (
    <div
      id="carouselExampleIndicators"
      className="carousel slide"
      data-interval="false"
    >
      <ol className="carousel-indicators">
        <li
          data-target="#carouselExampleIndicators"
          data-slide-to="0"
          className="active"
        ></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
      </ol>
      <div className="carousel-inner carousel-content">
        <div className="carousel-item active">
          <div className="d-flex">
            <span className="h3 ml-2">
              Create a new project by providing details such as the name,
              division, platform and whether you need the SDK or the REST APIs.
              <br /> Once a project is created, you can check the project
              bootstrap status in the Project Info screen.
            </span>
          </div>
          <img
            className="d-block w-100 px-2"
            src={require('../../assets/SectionOne.gif')}
            alt="First slide"
          />
        </div>
        <div className="carousel-item">
          <div className="d-flex">
            <span className="h3 ml-2">
              Once the project bootstrap is successful, the consumer key and
              consumer secret for the project can be copied.
              <br />
              These can be used in the Trimble Insights SDKs and REST APIs.
            </span>
          </div>
          <img
            className="d-block w-100"
            src={require('../../assets/SectionTwo.gif')}
            alt="Second Slide"
          />
        </div>
        <div className="carousel-item">
          <div className="d-flex">
            <span className="h3 ml-2">
              In the Data screen, the data files that were ingested into the
              Trimble Insights platform can be viewed and downloaded.
              <br /> The files list can also be filtered using date filters and
              region filters.
            </span>
          </div>
          <img
            className="d-block w-100"
            src={require('../../assets/SectionThree.gif')}
            alt="Third slide"
          />
        </div>
        <div className="carousel-item">
          <div className="d-flex">
            <span className="h3 ml-2">
              In the Executions screen, new ETL pipeline executions can be
              created for a project and their status can be tracked.
              <br /> One can also look at the status of the last ten executions
              for the project in this screen.
            </span>
          </div>
          <img
            className="d-block w-100"
            src={require('../../assets/SectionFour.gif')}
            alt="Fourth slide"
          />
        </div>
        <div className="carousel-item">
          <div className="d-flex">
            <span className="h3 ml-2">
              In the Users screen, new users can be added and existing users can
              be removed from the project.
              <br />
              Users can be added with either a User role or an Admin role. Only
              Admins can manage users in a project.
            </span>
          </div>
          <img
            className="d-block w-100"
            src={require('../../assets/SectionFive.gif')}
            alt="Fifth slide"
          />
        </div>
      </div>
      <button
        className="carousel-control-prev"
        data-target="#carouselExampleIndicators"
        role="button"
        data-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        data-target="#carouselExampleIndicators"
        role="button"
        data-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </button>
    </div>
  );
};

import 'styles/FilesTable.css';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useGetDataFilesQuery } from 'services/data/dataSlice';
import { downloadFile } from 'helpers/filesDownloadHelper';
import {
  getDefaultStartDate,
  getDefaultEndDate,
} from 'helpers/defaultDatesHeaders';
import config from 'config';
import { MessageSeverity } from 'types/enum/MessageSeverity';
import { messages } from 'components/common/messages';
import { CenteredSpinnerCircle } from 'components/common/CenteredSpinnerCircle';
import { DataFilesHeader } from './DataFilesHeader';
import WithToast from 'components/HOC/WithToast';
import { IPropTypesToast } from 'types/ToastTypes';
import { useLocation } from 'react-router-dom';
import {
  getSessionData,
  sessionConstants,
} from 'components/storage/sessionStorage';
import WithRouteValidation from 'components/HOC/WithRouteValidation';
import { IPropTypesRouteValidations } from 'types/RouteValidationsTypes';
import { ProjectStatusOption } from 'types/enum/ProjectEnum';
import { MAIL_ID, PAGE_SIZE } from 'helpers/constants';
import { sendCustomLog } from 'helpers/sdkCustomEventLogger';
import CustomTable, { ISelectRowProps } from 'components/common/CustomTable';

const FilesTable = (props: IPropTypesToast & IPropTypesRouteValidations) => {
  const columns = [
    {
      dataField: 'fileName',
      text: 'File Name',
      style: { width: '140vw' },
    },
    {
      dataField: 'creationTime',
      text: 'Created Date',
      style: { width: '80vw' },
    },
  ];

  const [selectedRows, setRowsSelected] = useState<string[]>([]);
  const pageSize = PAGE_SIZE;
  const [pageData, setPageData] = useState<any[]>([]);
  let [lastEvaluatedKey, setLastEvaluatedKey] = useState('');
  let [lastEvaluateKeysArr, setLastEvaluatedKeysArr] = useState<any[]>([]);
  let [page_idx, setPage_idx] = useState(0);
  let [startDate, setStartDate] = useState('');
  let [endDate, setEndDate] = useState('');
  let [loaderDownload, setLoaderDownload] = useState(false);
  const [region, setRegion] = useState('');
  const [chevronBtn, setChevronBtn] = useState('');
  let location = useLocation();

  let projectId = location.search.split('?projectId=')[1];
  const onApplyClickCallback = (startDateArg: string, endDateArg: string) => {
    setFirstMost(0);
    if (!endDateArg) {
      setEndDate('');
    }
    if (endDateArg && !endDate) {
      setEndDate(endDateArg);
    }
    if (startDateArg == endDateArg) {
      refetch();
    }
    if (
      startDateArg == startDate &&
      (endDateArg == endDate || !endDateArg || !endDate)
    ) {
      refetch();
    } else if (startDateArg && !endDateArg) {
      setStartDate(startDateArg);
    } else {
      setStartDate(startDateArg);
      setEndDate(endDateArg);
    }
  };
  const handleSetRegion = (region: string) => {
    setRegion(region);
  };
  // 1. Data FETCHING done through RTK Query using 'useGetDataFilesQuery'.
  const { data, isSuccess, isFetching, isError, error, refetch } =
    useGetDataFilesQuery({
      pageSize: pageSize,
      lastEvaluatedKey: lastEvaluatedKey,
      startDate: !startDate ? getDefaultStartDate() : startDate,
      endDate: !endDate ? getDefaultEndDate() : endDate,
      id: projectId,
      region: region,
    });

  // Here, firstMost flag is required for the reason that in case of inifinite scrolling, when the page has been scrolled upto
  // a specific threshold, then the api is called again to fetch more data(fetchMoreData) and therefore isFetching property of the
  // RTK hook 'useGetDataFilesQuery' becomes true and because of this the loader for the inifinite scroller(at the bottom of the data
  // list) as well as the Fetching Files (Spinner component, at the center of the screen) appears simulataneously.
  // So it is essential to keep a track that once the first set of items(25 list files) appears and on scrolling fetchMoreData is
  // triggered to set the nextToken so as to fetch the next list of data then firstMost has to be set to 1 which means that the
  // upcoming data is not the first set which means Fetching Files(Spinner) should not come up,
  // only the InfiniteScroll loader should come up.
  const [firstMost, setFirstMost] = useState(0);

  useEffect(() => {
    setLastEvaluatedKey('');
    setFirstMost(0);
    setPageData([]);
    setPage_idx(0);
  }, [startDate, endDate, region]);

  useEffect(() => {
    // data contains the returnedArr object(list of files)
    if (data) {
      // if first set of list is being loaded, then for that firstMost has to be set to 1 and the current page data has to be updated.
      if (firstMost == 0) {
        setPageData(() => [...data.returnedArr]);
        setFirstMost(1);
        setLastEvaluatedKeysArr(() => [data.lastEvaluatedKey]);
      } else {
        // updating the pageData when next set of 25 items are fetched.
        setPageData(() => [...data.returnedArr]);
        if (lastEvaluateKeysArr[page_idx] === undefined) {
          setLastEvaluatedKeysArr((prev) => [...prev, data.lastEvaluatedKey]);
        }
      }
    }
  }, [data?.returnedArr]);
  useEffect(() => {
    if (isError && error && 'data' in error) {
      if (error.status >= 400 && error.status <= 499) {
        props.handleSetToastProps(error.data.error, MessageSeverity.error);
      }
      if (error.status >= 500) {
        props.handleSetToastProps(
          messages.INTERNAL_SERVER_ERROR,
          MessageSeverity.error
        );
      }
    }
  }, [isError]);
  useEffect(() => {
    if (!(page_idx - 1 < 0))
      setLastEvaluatedKey(lastEvaluateKeysArr[page_idx - 1]);
    else setLastEvaluatedKey('');
  }, [page_idx]);
  const handleDownloadBtnClick = async () => {
    setLoaderDownload(true);
    let fileNames = [];
    for (let idx = 0; idx < selectedRows.length; idx++) {
      fileNames.push(selectedRows[idx]);
      //2. Files Downloading API hitting through axios call.
      await axios
        .get(
          `${
            config.TRIMBLE_INSIGHTS_PROJECTS_SERVICE.BASE_URL
          }projects/${projectId}/files/${selectedRows[idx]}${
            region && region.toLocaleLowerCase() == 'eu'
              ? `?region=${region.toLocaleLowerCase()}`
              : ``
          }`,
          {
            headers: {
              Authorization: `Bearer ${getSessionData(sessionConstants.token)}`,
            },
          }
        )
        .then((response) => {
          downloadFile(response.data, selectedRows[idx]);
        })
        .catch((error) => {
          props.handleSetToastProps(
            messages.DOWNLOAD_FAILURE,
            MessageSeverity.error
          );
          setLoaderDownload(false);
          console.log('error ' + error);
        });
    }
    sendCustomLog('DOWNLOAD_INPUT_FILE', 'FILES', { fileNames });
    setRowsSelected([]);
    setTimeout(() => {
      setLoaderDownload(false);
    }, 2200);
  };

  // On reaching the threshold in the infinite scroll, fetchMoreData is called
  // which sets up the next token in the state from the current data which triggers the API call.
  const fetchMoreData = (arg: string) => {
    if (arg == 'Previous') {
      setChevronBtn('Previous');
      setLastEvaluatedKey(lastEvaluateKeysArr[page_idx - 1]);
      setPage_idx((idx) => idx - 1);
      setFirstMost(1);
    } else {
      setChevronBtn('Next');
      setPage_idx((idx) => idx + 1);
      setFirstMost(1);
      setLastEvaluatedKey(data?.lastEvaluatedKey);
    }
  };

  const handleOnSelect = (row: any, isSelect: any) => {
    if (isSelect) {
      if (selectedRows.length >= 10) {
        props.handleSetToastProps(
          messages.MAX_FILES_SELECTION_LIMIT,
          MessageSeverity.warning
        );
      } else {
        setRowsSelected([...selectedRows, row.file_name]);
      }
    } else {
      setRowsSelected(selectedRows.filter((x: any) => x !== row.file_name));
    }
  };
  const handleOnSelectAll = (row: any, isSelect: any) => {
    if (row) {
      if (isSelect?.length < 25) {
        isSelect.forEach((row: any) => {
          setRowsSelected([...selectedRows, row.file_name]);
        });
      }
    } else {
      setRowsSelected([]);
    }
  };
  const selectRowP: ISelectRowProps = {
    mode: 'checkbox',
    hideSelectAll: true,
    clickToSelect: true,
    selected: [],
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
  };

  const isLoaderForFirstLoad = isFetching && firstMost == 0;
  const renderSpinner = (message: string) => {
    return <CenteredSpinnerCircle loadingText={message} />;
  };
  const isShowTable =
    isSuccess &&
    (data.returnedArr.length && !pageData.length
      ? data.returnedArr.length > 0
      : pageData.length > 0);
  const renderTable = () => {
    if (isShowTable) {
      return (
        <>
          <div
            id="scrollableDiv"
            style={{ maxHeight: '70vh', overflowY: 'auto' }}
          >
            <CustomTable
              keyField="file_name"
              data={
                data.returnedArr.length && !pageData.length
                  ? data.returnedArr
                  : pageData
              }
              columns={columns}
              selectRowProps={selectRowP}
              selectedRows={selectedRows}
            />
          </div>
        </>
      );
    }
  };
  let isNextBtnDisabled =
    !data?.lastEvaluatedKey || isError || data?.lastEvaluatedKey == 'null';
  let isPreviousBtnDisabled = page_idx == 0;
  const renderPaginationButtons = () => {
    return (
      <div className="d-flex justify-content-end">
        <nav aria-label="Page navigation example">
          <ul className="pagination pagination-md">
            <li className="page-item">
              <button
                className="page-link"
                aria-label="Previous"
                disabled={isPreviousBtnDisabled}
                onClick={() => fetchMoreData('Previous')}
                style={{
                  cursor: isPreviousBtnDisabled ? 'not-allowed' : 'pointer',
                }}
              >
                <i className="modus-icons notranslate" aria-hidden="true">
                  chevron_left
                </i>
              </button>
            </li>
            <li className="page-item">
              <button
                className="page-link"
                aria-label="Next"
                disabled={isNextBtnDisabled}
                onClick={() => fetchMoreData('Next')}
                style={{
                  cursor: isNextBtnDisabled ? 'not-allowed' : 'pointer',
                }}
              >
                <i className="modus-icons notranslate" aria-hidden="true">
                  chevron_right
                </i>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    );
  };
  const isLoaderForPagination = isFetching && firstMost != 0;
  const isNoFiles = isSuccess && !isFetching && data.returnedArr.length == 0;
  const noFilesInDateRange = () => {
    if (isNoFiles) {
      return (
        <div className="col-12 d-flex justify-content-center nullFilesCustom">
          <div className="container d-flex justify-content-center align-items-center">
            <span className="display-3 font-weight-light">No files</span>
          </div>
        </div>
      );
    }
  };
  return (
    <>
      <DataFilesHeader
        allProjectDetails={props.allProjectDetails}
        handleSetToastProps={props.handleSetToastProps}
        projectName={props.projectName}
        onApplyClickCallback={onApplyClickCallback}
        handleSetRegion={handleSetRegion}
        region={region}
        selectedRows={selectedRows}
        handleDownloadBtnClick={handleDownloadBtnClick}
      />
      {loaderDownload && renderSpinner('Downloading Selected File(s)')}
      {isLoaderForFirstLoad && renderSpinner('Fetching Data Files')}
      {isLoaderForPagination && renderSpinner(`Loading ${chevronBtn} Page`)}
      {renderPaginationButtons()}
      <main>
        {isShowTable && renderTable()}
        {isNoFiles && noFilesInDateRange()}
        {props.projectStatus &&
          props.projectStatus == ProjectStatusOption.SUCCEEDED &&
          !isShowTable &&
          !isSuccess &&
          !isFetching && (
            <div className="col-12 d-flex justify-content-center nullFilesCustom">
              <div className="container d-flex justify-content-center align-items-center">
                <span className="display-3 font-weight-light">
                  Something went wrong.
                </span>
              </div>
            </div>
          )}
        {props.projectStatus &&
          props.projectStatus !== ProjectStatusOption.SUCCEEDED &&
          !isFetching && (
            <div className="col-12 d-flex justify-content-center nullFilesCustom">
              <div className="container d-flex justify-content-center align-items-center">
                <span className="h2 font-weight-light">
                  The project has not yet been setup in Trimble Insights.
                  <br />
                  Please contact <a href={`mailto:${MAIL_ID}`}>{MAIL_ID}</a>
                </span>
              </div>
            </div>
          )}
      </main>
    </>
  );
};

const FilesTableWithToast = WithToast(FilesTable);
const FilesTableWithToastWithRouteValidation =
  WithRouteValidation(FilesTableWithToast);
export default FilesTableWithToastWithRouteValidation;

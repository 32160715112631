import { Alert } from 'react-bootstrap';
import { AlertTypes } from 'types/AlertTypes';
import React from 'react';

interface AlertBoxTypes {
  alert: AlertTypes;
  handleInput?(): void;
  dismissble: boolean;
}

export const AlertBox = (props: AlertBoxTypes) => {
  return (
    <Alert
      variant={props.alert.severity}
      onClose={props.handleInput}
      dismissible={props.dismissble ? true : undefined}
    >
      <Alert.Heading> {props.alert.message} </Alert.Heading>
    </Alert>
  );
};

import { Breadcrumb } from '@trimbleinc/modus-react-bootstrap';
import routerPath from 'config/routerPath';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IPropTypesDateFilter } from 'types/DateFilterTypes';
import { IPropTypesDownloadButton } from 'types/DownloadButtonTypes';
import { IPropTypesToast } from 'types/ToastTypes';
import { DateFilter } from './DateFilter';
import { DownloadButton } from './DownloadButton';
import 'styles/FilesTable.css';
import { IPropTypesRouteValidations } from 'types/RouteValidationsTypes';

interface IPropTypesWrapperDatesFileHeader
  extends IPropTypesToast,
    IPropTypesDateFilter,
    IPropTypesDownloadButton {}

export const DataFilesHeader = (
  props: IPropTypesWrapperDatesFileHeader & IPropTypesRouteValidations
) => {
  let navigate = useNavigate();
  let location = useLocation();
  let projectId = location.search.split('?projectId=')[1];
  return (
    <>
      <div className="row ">
        <Breadcrumb className="breadcrumbCustom mt-1">
          <Breadcrumb.Item
            onClick={() => {
              navigate(routerPath.PRIVATE_ROUTE.projectsHomePath);
            }}
          >
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item
            onClick={() => {
              navigate(`/${routerPath.PRIVATE_ROUTE.projectPath}/${projectId}`);
            }}
          >
            {props?.projectName ? props.projectName : 'Project Does Not Exist'}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Data</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div
        className="d-flex flex-md-row
      flex-sm-column flex-xs-column
      justify-content-between row"
      >
        <div className="h1 font-weight-bold col-md-2 col-xs-12">
          Input Files
        </div>
        <div className="d-flex flex-column flex-md-row col-md-8 justify-content-center col-xs-12">
          <DateFilter
            handleSetToastProps={props.handleSetToastProps}
            onApplyClickCallback={props.onApplyClickCallback}
            handleSetRegion={props.handleSetRegion}
            region={props.region}
          />
        </div>
        <div className="col-md-2 col-xs-12 d-flex justify-content-start justify-content-md-end">
          <DownloadButton
            selectedRows={props.selectedRows}
            handleDownloadBtnClick={props.handleDownloadBtnClick}
          />
        </div>
      </div>
    </>
  );
};

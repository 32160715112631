import React from 'react';
import { toast, TypeOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AlertTypes } from 'types/AlertTypes';
interface ToastBoxTypes {
  alert: AlertTypes;
  handleClose?: () => void;
}

export const ToastBox = (props: ToastBoxTypes) => {
  let handleCheck = true;
  const handleToast = () => {
    handleCheck = false;
    toast(props.alert.message, {
      closeOnClick: false,
      hideProgressBar: false,
      position: 'top-right',
      autoClose: 5000,
      theme: 'light',
      type: props.alert.severity as TypeOptions,
    });

    toast.onChange((event) => {
      if (event.status === 'removed') {
        props.handleClose && props.handleClose();
      }
    });
  };

  React.useEffect(() => {
    handleCheck && props.alert.message && handleToast();
  }, [props.alert.message]);
  return <div />;
};

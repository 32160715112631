import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from 'config';
import path from 'config/urlPath';
import { setHeaders } from 'services/apiHeaders';
import { OauthTypes } from 'types/OauthTypes';

const generateParams = (params: OauthTypes): URLSearchParams => {
  const queryParams = new URLSearchParams();
  queryParams.append('grant_type', 'authorization_code');
  queryParams.append('client_id', config.AUTH_APP_SERVICE.ClientId);
  queryParams.append(
    'redirect_uri',
    config.AUTH_APP_SERVICE.WEBAPP_URL + path.AUTH_API_SERVICE_URL.redirect
  );
  queryParams.append('tenantDomain', 'Trimble.com');
  queryParams.append('code', params.code);
  queryParams.append('code_verifier', params.code_verifier);
  return queryParams;
};

export const authTokenSlice = createApi({
  reducerPath: 'authCodeSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: config.AUTH_APP_SERVICE.BASE_URL,
    prepareHeaders(headers) {
      return setHeaders(headers, 'authTokenSlice');
    },
  }),
  endpoints: (builder) => ({
    getToken: builder.mutation<any, OauthTypes>({
      query: (params) => ({
        url: path.AUTH_API_SERVICE_URL.oauthToken,
        method: 'POST',
        body: generateParams(params),
      }),
      transformResponse: (data: any) => {
        return data;
      },
    }),
  }),
});

export const { useGetTokenMutation } = authTokenSlice;

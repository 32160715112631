import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface RenderTooltipProps {
  children: React.ReactElement;
  overlayText: string;
}

export const RenderTooltip = (props: RenderTooltipProps) => {
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 250 }}
      overlay={<Tooltip>{props.overlayText}</Tooltip>}
    >
      {props.children}
    </OverlayTrigger>
  );
};

import { Modal, Button } from 'react-bootstrap';
import React from 'react';

interface ConfirmationDialogTypes {
  dialogHeader: string;
  dialogContent: string;
  onNo(): void;
  onYes(): void;
}

export const ConfirmationDialog = (props: ConfirmationDialogTypes) => {
  return (
    <Modal size="lg" centered show={true} onHide={props.onNo}>
      <Modal.Header closeButton>
        <Modal.Title id="modal-vcenter">{props.dialogHeader}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{props.dialogContent}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onYes}>Yes</Button>
        <Button onClick={props.onNo}>No</Button>
      </Modal.Footer>
    </Modal>
  );
};

import React from 'react';
import config from 'config';
import path from 'config/urlPath';
import {
  localConstants,
  storeLocalData,
} from 'components/storage/localStorage';
import { generateRandomString } from 'common/commonFunction';
export const TidLogin = () => {
  const hexToBase64 = (str: any) => {
    return btoa(
      String.fromCharCode(
        ...str
          .replace(/\r|\n/g, '')
          .replace(/([\da-fA-F]{2}) ?/g, '0x$1 ')
          .replace(/ +$/, '')
          .split(' ')
      )
    );
  };

  const getChallenge = (code_verifier: string): string => {
    const SHA256 = require('sha256');
    const hash = SHA256(code_verifier);
    let code_challenge = hash.replaceAll('=', '');
    code_challenge = hexToBase64(code_challenge)
      .replaceAll('=', '')
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_');
    return code_challenge;
  };

  const getCode = async () => {
    const code_verifier = generateRandomString(128);
    const code_challenge = getChallenge(code_verifier);
    storeLocalData(
      {
        key: localConstants.codeVerifier,
        value: code_verifier,
      },
      {
        key: localConstants.codeChallenge,
        value: code_challenge,
      }
    );
    window.open(
      config.AUTH_APP_SERVICE.OPEN_URL.replace(
        '{client_id}',
        config.AUTH_APP_SERVICE.ClientId
      )
        .replace('{AppName}', config.AUTH_APP_SERVICE.AppName)
        .replace(
          '{redirect_uri}',
          config.AUTH_APP_SERVICE.WEBAPP_URL +
            path.AUTH_API_SERVICE_URL.redirect
        )
        .replace('{code_challenge}', code_challenge),
      '_self'
    );
  };

  React.useEffect(() => {
    getCode();
  }, []);

  return <div></div>;
};

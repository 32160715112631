import { Nav, Dropdown, NavItem } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { PersonCircle, QuestionSquare } from 'react-bootstrap-icons';
import jwt from 'jwt-decode';
import config from 'config';
import path from 'config/urlPath';
import {
  getSessionData,
  sessionConstants,
} from 'components/storage/sessionStorage';
import RouterPath from 'config/routerPath';
import { useLocation, useNavigate } from 'react-router-dom';
import 'styles/NavBar.css';
import { HelpModal } from 'components/common/HelpModal';
import {   useGetProjectDetailsQuery, useGetProjectStatusQuery } from 'services/project/projectSlice';
import { ProjectStatusOption } from 'types/enum/ProjectEnum';
import { toast} from 'react-toastify';
import { messages } from 'components/common/messages';


interface userJwt {
  given_name: string;
  family_name: string;
  email: string;
}

const handleLogout = () => {
  window.open(
    config.AUTH_APP_SERVICE.LOGOUT_URL.replace(
      '{id_token_hint}',
      getSessionData(sessionConstants.idToken)
    ).replace(
      '{post_logout_redirect_uri}',
      config.AUTH_APP_SERVICE.WEBAPP_URL + path.AUTH_API_SERVICE_URL.logout
    ),
    '_self'
  );
};

export const NavBar = () => {
  const [showHelpGuide, setShowHelpGuide] = useState(false);

  const handleClose = () => setShowHelpGuide(false);
  const handleShow = () => setShowHelpGuide(true);

  const navigate = useNavigate();
  const idToken = getSessionData(sessionConstants.idToken);
  var user: userJwt = jwt(idToken !== null ? idToken : '');
  let location = useLocation();
  const [projectId, setProjectId] = useState('');
  useEffect(() => {
    if (location.pathname == '/') {
      setStatus(ProjectStatusOption.UNKNOWN);
    }
    if (location.pathname.startsWith('/projects')) {
      if (location.pathname.trim().split('/')[2]) {
        setProjectId(location.pathname.trim().split('/')[2]);
      }
    } else if (
      location.pathname == '/data' ||
      location.pathname == '/executions' ||
      location.pathname == '/users' ||
      location.pathname == '/configurations'
    ) {
      if (location.search.startsWith('?projectId')) {
        setProjectId(location.search.split('?projectId=')[1]);
      } else {
        navigate(RouterPath.PRIVATE_ROUTE.notFound);
        return;
      }
    } else {
      setProjectId('');
    }
  }, [location]);
  //
  const [status, setStatus] = useState<ProjectStatusOption>();
  const [_showProjectSpecificNavItems, setShowProjectSpecificNavItems] =
    useState(false);
  // Here, the status of the Project(based on the project id) is polled,
  // which yields results suchs as "FAILED" or "SUCCEEDED".
  // Only if the status of the Project is "SUCCEEDED", project specific Nav Items(Home,Data,Executions)
  // will be displayed.
  const {
    isSuccess: isStatusSuccess,
    data: projectStatusData,
    isFetching: fetchingProjectStatus,
    isError: isStatusError,
    refetch,
  } = useGetProjectStatusQuery(projectId, { skip: projectId ? false : true });
  const { data : projectDetails} = useGetProjectDetailsQuery(projectId);
  useEffect(()=>{
  if (projectDetails?.deleteStatus == "InProgress") {
    toast.warning(messages.DELETION_INPROGRESS);
  }
  else if (projectDetails?.deleteStatus == "Failed") {
    toast.error(messages.DELETION_FAILED);
  }},[projectDetails?.deleteStatus])
  useEffect(() => {
    if (!fetchingProjectStatus) {
      if (isStatusSuccess) {
        let projectStatusOptionArray = Object.values(ProjectStatusOption);
        if (projectStatusOptionArray.includes(projectStatusData.status)) {
          setStatus(projectStatusData.status);
        } else {
          setStatus(ProjectStatusOption.UNKNOWN);
        }
        setShowProjectSpecificNavItems(true);
      }
    }
  }, [isStatusSuccess, fetchingProjectStatus]);
  useEffect(() => {
    if (isStatusError) {
      navigate(RouterPath.PRIVATE_ROUTE.notFound);
    }
  }, [isStatusError]);
  useEffect(() => {
    if (projectId != location.search.split('?projectId=')[1]) {
      projectId && refetch();
    }
  }, [projectId]);
  const handleNavigate = (path: string) => {
    projectId && path != RouterPath.PUBLIC_ROUTE.documentationPath
      ? navigate({ pathname: path, search: `projectId=${projectId}` })
      : navigate(path);
  };
  return (
    <>
      <Nav
        className="navbar navbar-blue sticky-top pl-3 homeNav d-flex justify-content-between"
        variant="primary"
      >
        <div>
          <img
            src="https://modus-bootstrap.trimble.com/img/trimble-icon-rev.svg"
            width="35px"
            height="35px"
            onClick={() => {
              navigate(RouterPath.PRIVATE_ROUTE.projectsHomePath);
            }}
            className="cursor"
          ></img>
          <h2
            className="navbar-text text-white p-0 p-2 m-0 mr-3 d-md-inline d-none cursor"
            onClick={() => {
              navigate(RouterPath.PRIVATE_ROUTE.projectsHomePath);
            }}
          >
            Trimble Insights
          </h2>
          {/* add conditions here for the project specific tabs to remain unrendered. */}
          {location.pathname != '/' &&
            location.pathname != `${RouterPath.PRIVATE_ROUTE.notFound}` &&
            location.pathname !=
              `/${RouterPath.PRIVATE_ROUTE.createProjectPath}` && (
              <>
                <NavItem
                  className={
                    window.location.pathname ===
                    `/${RouterPath.PRIVATE_ROUTE.projectPath}/${projectId}`
                      ? 'navbar-text text-white  h-100 px-3 bg-trimble-blue cursor'
                      : 'navbar-text text-white  h-100 px-3 cursor'
                  }
                  id="projectInfo"
                  onClick={() => {
                    navigate(
                      `/${RouterPath.PRIVATE_ROUTE.projectPath}/${projectId}`
                    );
                  }}
                >
                  Project Info
                </NavItem>
                {!fetchingProjectStatus && projectDetails?.deleteStatus == null  &&
                  status == ProjectStatusOption.SUCCEEDED && (
                    <>
                      <NavItem
                        className={
                          window.location.pathname ===
                          RouterPath.PRIVATE_ROUTE.dataPath
                            ? 'navbar-text text-white  h-100 px-3 bg-trimble-blue cursor'
                            : 'navbar-text text-white  h-100 px-3 cursor'
                        }
                        id="data"
                        onClick={() => {
                          handleNavigate(RouterPath.PRIVATE_ROUTE.dataPath);
                        }}
                      >
                        Data
                      </NavItem>
                      <NavItem
                        className={
                          window.location.pathname ===
                          RouterPath.PRIVATE_ROUTE.executionPath
                            ? 'navbar-text text-white  h-100 px-3 bg-trimble-blue cursor'
                            : 'navbar-text text-white  h-100 px-3 cursor'
                        }
                        id="execution"
                        onClick={() => {
                          handleNavigate(
                            RouterPath.PRIVATE_ROUTE.executionPath
                          );
                        }}
                      >
                        Executions
                      </NavItem>
                      <NavItem
                        className={
                          window.location.pathname ===
                          `${RouterPath.PRIVATE_ROUTE.configurationPath}`
                            ? 'navbar-text text-white  h-100 px-3 bg-trimble-blue cursor'
                            : 'navbar-text text-white  h-100 px-3 cursor'
                        }
                        id="configurations"
                        onClick={() => {
                          handleNavigate(
                            RouterPath.PRIVATE_ROUTE.configurationPath
                          );
                        }}
                      >
                        Configurations
                      </NavItem>
                      <NavItem
                        className={
                          window.location.pathname ===
                          `/${RouterPath.PRIVATE_ROUTE.usersPath}`
                            ? 'navbar-text text-white  h-100 px-3 bg-trimble-blue cursor'
                            : 'navbar-text text-white  h-100 px-3 cursor'
                        }
                        id="users"
                        onClick={() => {
                          handleNavigate(RouterPath.PRIVATE_ROUTE.usersPath);
                        }}
                      >
                        Users
                      </NavItem>
                    </>
                  )}  
              </>
            )}
        </div>
        <div className="d-flex">
          <NavItem
            className={
              window.location.pathname ===
              `/${RouterPath.PUBLIC_ROUTE.documentationPath}`
                ? 'navbar-text text-white  h-100 px-3 bg-trimble-blue cursor'
                : 'navbar-text text-white  h-100 px-3 cursor'
            }
            id="docs"
            onClick={() => {
              handleNavigate(RouterPath.PUBLIC_ROUTE.documentationPath);
            }}
          >
            Docs
          </NavItem>
          <NavItem
            className={
              showHelpGuide ? 'cursor px-3 bg-trimble-blue' : 'cursor px-3'
            }
            id="help"
            onClick={handleShow}
          >
            <div className="d-flex mt-2 ">
              <QuestionSquare className="h1" color="#fbffff" />
              <span className="px-2 text-white ">Help</span>
            </div>
          </NavItem>
          <Dropdown className="ml-auto mr-2">
            <Dropdown.Toggle
              variant="primary"
              className="navbar-blue mt-1"
              bsPrefix="border-0"
            >
              <PersonCircle size={20}></PersonCircle>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.ItemText>
                {`${user.given_name} ${user.family_name}`}
              </Dropdown.ItemText>
              <Dropdown.ItemText>{` ${user.email}`}</Dropdown.ItemText>
              <Dropdown.Divider></Dropdown.Divider>
              <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Nav>
      <HelpModal show={showHelpGuide} handleClose={handleClose} />
    </>
  );
};

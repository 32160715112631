import jwt from 'jwt-decode';
function InitLogger() {
  var user = jwt(sessionStorage.getItem('id.token'));
  window.trimbleInsightsLogger.init({
    headers: {
      clientId: 'c15d8c47-b6c9-45a5-aaa4-181834a033cf',
      clientSecret: '2c3a4b80cd49407a8ed153bee0177053',
      scope: 'insights-portal',
    },
    npsModalStyleProps: {
      backgroundDim: false,
    },
    interval: 1000,
    trackEvents: {},
    isNpsEnabled: true,
    userId: user.sub,
  });
  return null;
}

export default InitLogger;

import React from 'react';
import { Form } from 'react-bootstrap';
interface FormDropDownProps {
  value: string;
  controlId: string;
  label: string;
  error?: string;
  handleInput(value: string): void;
  disabled: boolean;
  options: string[];
  className?: string;
  defaultValue: string;
}

const Styles = {
  formLabel: {
    fontSize: '14px',
  },
  formContent: {
    fontSize: '16px',
    height: '50px',
  },
};

export const FormDropDown = (props: FormDropDownProps) => {
  return (
    <div>
      <Form.Group
        className={props.className ? props.className : 'mb-4'}
        controlId={props.controlId}
      >
        <Form.Label className="text-secondary" style={Styles.formLabel}>
          {props.label}
        </Form.Label>
        <Form.Select
          disabled={props.disabled}
          required
          defaultValue={props.defaultValue}
          className={
            props.error ? 'is-invalid custom-select m-0' : 'custom-select m-0'
          }
          size="lg"
          style={Styles.formContent}
          value={props.value}
          onInput={(event) => {
            props.handleInput((event.target as HTMLInputElement).value);
          }}
        >
          {props.options.map((obj) => {
            return (
              <option value={obj} key={obj}>
                {obj}
              </option>
            );
          })}
        </Form.Select>
        {props.error && (
          <Form.Text className="text-danger">{props.error}</Form.Text>
        )}
      </Form.Group>
    </div>
  );
};

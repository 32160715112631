import { configureStore, MiddlewareArray } from '@reduxjs/toolkit';
import { authTokenSlice } from 'services/oauth/authTokenSlice';
import { dataSlice } from 'services/data/dataSlice';
import { projectSlice } from 'services/project/projectSlice';
import { executionSlice } from 'services/execution/executionSlice';
import { userSlice } from 'services/users/userSlice';
import routerPath from 'config/routerPath';
import { configurationSlice } from 'services/configuration/configurationSlice';
const loggerMiddleware = (store: any) => (next: any) => (action: any) => {
  console.log(store);
  if (action?.payload?.status === 401) {
    window.open(`/${routerPath.PUBLIC_ROUTE.loginTIDPath}`, '_self');
  }
  return next(action);
};

export const store: any = configureStore({
  reducer: {
    [authTokenSlice.reducerPath]: authTokenSlice.reducer,
    [dataSlice.reducerPath]: dataSlice.reducer,
    [projectSlice.reducerPath]: projectSlice.reducer,
    [executionSlice.reducerPath]: executionSlice.reducer,
    [configurationSlice.reducerPath]: configurationSlice.reducer,
    [userSlice.reducerPath]: userSlice.reducer,
  },
  middleware(getDefaultMiddleware) {
    return new MiddlewareArray()
      .concat(getDefaultMiddleware())
      .concat(authTokenSlice.middleware)
      .concat(dataSlice.middleware)
      .concat(projectSlice.middleware)
      .concat(executionSlice.middleware)
      .concat(userSlice.middleware)
      .concat(loggerMiddleware);
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

import React from 'react';
import '@trimbleinc/modus-bootstrap/dist/modus.min.css';
import { default as FilesTable } from 'components/Data/FilesTable';
export const Data = () => {
  return (
    <div className="container col-12">
      <div className="container-fluid mt-1">
        <FilesTable />
      </div>
    </div>
  );
};

import React, { useEffect } from 'react';
import {
  useCreateExecutionMutation,
  useGetExecutionFilesQuery,
} from 'services/execution/executionSlice';
import 'styles/Executions.css';
import { CenteredSpinnerCircle } from 'components/common/CenteredSpinnerCircle';
import { MessageSeverity } from 'types/enum/MessageSeverity';
import { ExecutionsHeader } from './ExecutionsHeader';
import WithToast from 'components/HOC/WithToast';
import { IPropTypesToast } from 'types/ToastTypes';
import { useLocation, useNavigate } from 'react-router-dom';
import WithRouteValidation from 'components/HOC/WithRouteValidation';
import { IPropTypesRouteValidations } from 'types/RouteValidationsTypes';
import { ProjectStatusOption } from 'types/enum/ProjectEnum';
import { Breadcrumb } from '@trimbleinc/modus-react-bootstrap';
import routerPath from 'config/routerPath';
import { MAIL_ID } from 'helpers/constants';
import { messages } from 'components/common/messages';
import { sendCustomLog } from 'helpers/sdkCustomEventLogger';
import CustomTable from 'components/common/CustomTable';
import { useGetProjectDetailsQuery } from 'services/project/projectSlice';

const Executions = (props: IPropTypesToast & IPropTypesRouteValidations) => {
  const columns = [
    {
      dataField: 'executionId',
      text: 'Execution ID',
      style: { width: '120vw' },
    },
    {
      dataField: 'status',
      text: 'Status',
      style: { width: '120vw' },
    },
    {
      dataField: 'startDate',
      text: 'Start Date',
      style: { width: '80vw' },
    },
    {
      dataField: 'duration',
      text: 'Duration',
      style: { width: '80vw' },
    },
  ];

  let location = useLocation();
  let searchParamProjectId = location.search.split('?projectId=')[1];
  let navigate = useNavigate();

  // 1. API call for getting the list of executions.
  const {
    data,
    isLoading,
    isFetching,
    isSuccess,
    isError,
    error: getExecutionsErrorResponse,
  } = useGetExecutionFilesQuery({
    id: searchParamProjectId,
  });

  // 2. API call for getting the project details.
  const {
    data: projectDetails,
    isSuccess: isSuccessProjectDetails,
    refetch,
  } = useGetProjectDetailsQuery(searchParamProjectId);

  useEffect(() => {
    refetch();
  }, [isSuccessProjectDetails, isFetching]);

  const availableExecutionsCount = projectDetails?.availableExecutions;

  // 3. API call for creation of a new execution.
  const [
    createExecution,
    {
      data: responseForExecCreation,
      isLoading: isLoadingCreation,
      isError: executionError,
      error: responseForExecFailed,
    },
  ] = useCreateExecutionMutation();

  useEffect(() => {
    //create execution error handling
    if (
      executionError &&
      responseForExecFailed &&
      'data' in responseForExecFailed
    ) {
      if (
        responseForExecFailed.status >= 400 &&
        responseForExecFailed.status <= 499
      ) {
        if (responseForExecFailed.status === 403) {
          props.handleSetToastProps(
            messages.EXECUTIONS_LIMIT_EXCEEDED,
            MessageSeverity.warning
          );
        } else {
          props.handleSetToastProps(
            responseForExecFailed.data.error,
            MessageSeverity.error
          );
        }
      }
      if (responseForExecFailed.status >= 500) {
        props.handleSetToastProps(
          responseForExecFailed.data.error,
          MessageSeverity.warning
        );
      }
    } else {
      if (!executionError && responseForExecCreation?.executionId) {
        sendCustomLog('CREATE_EXECUTION', 'EXECUTIONS', {
          executionId: responseForExecCreation.executionId,
        });
      }
    }
    //get executions error handling
    if (
      isError &&
      getExecutionsErrorResponse &&
      'data' in getExecutionsErrorResponse
    ) {
      if (
        getExecutionsErrorResponse.status >= 400 &&
        getExecutionsErrorResponse.status <= 499
      ) {
        props.handleSetToastProps(
          getExecutionsErrorResponse.data.error,
          MessageSeverity.error
        );
      }
      if (getExecutionsErrorResponse.status >= 500) {
        props.handleSetToastProps(
          messages.INTERNAL_SERVER_ERROR,
          MessageSeverity.error
        );
      }
    }
  }, [
    isSuccess,
    isError,
    executionError,
    responseForExecCreation,
  ]);
  const handleCreateExecutionBtnClick = (e: any) => {
    if (availableExecutionsCount > 0) {
      e.preventDefault();
      createExecution({
        id: searchParamProjectId,
      });
    } else {
      props.handleSetToastProps(
        messages.EXECUTIONS_LIMIT_EXCEEDED,
        MessageSeverity.warning
      );
    }
  };

  const renderSpinner = (message: string) => {
    return <CenteredSpinnerCircle loadingText={message} />;
  };

  let isUpdatingExecutions = !isLoading && isFetching;

  const renderTable = () => {
    return (
      <div className=" mt-md-3 mt-2 execution-container">
        <CustomTable
          keyField="executionArn"
          data={data}
          columns={columns}
          rowStyle={{ height: '7vh' }}
        />
      </div>
    );
  };
  const isNoFiles =
    isSuccess && !isLoadingCreation && !isFetching && data.length == 0;
  const renderNoExecFiles = () => {
    if (isNoFiles) {
      return (
        <div className="col-12 d-flex justify-content-center nullFilesCustom">
          <div className="container d-flex justify-content-center align-items-center">
            <span className="display-3 font-weight-light">No executions</span>
          </div>
        </div>
      );
    }
  };
  return (
    <>
      <div className="container col-12">
        <div className="container-fluid mt-1">
          <div className="row ">
            <Breadcrumb className="breadcrumbCustom mt-1">
              <Breadcrumb.Item
                onClick={() => {
                  navigate(routerPath.PRIVATE_ROUTE.projectsHomePath);
                }}
              >
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item
                onClick={() => {
                  navigate(
                    `/${routerPath.PRIVATE_ROUTE.projectPath}/${searchParamProjectId}`
                  );
                }}
              >
                {props?.projectName
                  ? props.projectName
                  : 'Project Does Not Exist'}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Executions</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <ExecutionsHeader
            isLoadingGetExecutionsList={isLoading}
            isErrorInGetExecutionsList={isError}
            isLoadingCreation={isLoadingCreation}
            isUpdatingExecutions={isUpdatingExecutions}
            handleCreateExecutionBtnClick={handleCreateExecutionBtnClick}
          />
          {isLoading && renderSpinner('Loading Executions')}
          {isUpdatingExecutions && renderSpinner('Updating Executions List')}
          {isLoadingCreation && renderSpinner('Creating an Execution')}
          {isSuccess && !isFetching && !isNoFiles && renderTable()}
          {isNoFiles && renderNoExecFiles()}
          {props.projectStatus &&
            props.projectStatus == ProjectStatusOption.SUCCEEDED &&
            !isSuccess &&
            !isFetching && (
              <div className="col-12 d-flex justify-content-center nullFilesCustom">
                <div className="container d-flex justify-content-center align-items-center">
                  <span className="display-3 font-weight-light">
                    Something went wrong.
                  </span>
                </div>
              </div>
            )}
          {props.projectStatus &&
            props.projectStatus !== ProjectStatusOption.SUCCEEDED &&
            !isFetching && (
              <div className="col-12 d-flex justify-content-center nullFilesCustom">
                <div className="container d-flex justify-content-center align-items-center">
                  <span className="h2 font-weight-light">
                    The project has not yet been setup in Trimble Insights.
                    <br />
                    Please contact <a href={`mailto:${MAIL_ID}`}>{MAIL_ID}</a>
                  </span>
                </div>
              </div>
            )}
        </div>
      </div>
    </>
  );
};
const ExecutionsWithToast = WithToast(Executions);
const ExecutionsWithToastWithRouteValidation =
  WithRouteValidation(ExecutionsWithToast);
export default ExecutionsWithToastWithRouteValidation;

import React from 'react';
import 'styles/FilesTable.css';
import {
  SpinnerCircle,
  SpinnerCircleProps,
} from 'components/common/SpinnerCircle';
export const CenteredSpinnerCircle = ({ loadingText }: SpinnerCircleProps) => {
  return (
    <div className="container d-flex justify-content-center align-items-center centered">
      <SpinnerCircle loadingText={loadingText} />
    </div>
  );
};

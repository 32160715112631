import React, { useState } from 'react';
import { Button, Container, Form, Row, Col } from 'react-bootstrap';
import { CenteredSpinnerCircle } from 'components/common/CenteredSpinnerCircle';
import { useNavigate, useParams } from 'react-router-dom';
import { EyeFill, EyeSlashFill, ClipboardData } from 'react-bootstrap-icons';
import { FormField } from 'components/common/FormField';
import { FormTextArea } from 'components/common/FormTextArea';
import { FormRadioButton } from 'components/common/FormRadioButton';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormDropDown } from 'components/common/FormDropDown';
import { ProjectTypes } from 'types/ProjectTypes';
import {
  ProjectDivisionOption,
  ProjectLanguageOption,
  ProjectTypeOption,
  ProjectUseSDKRestApi,
  ProjectTrackDefaultEventsOption,
} from 'types/enum/ProjectEnum';
import { RenderTooltip } from 'components/common/RenderTooltip';
import { MessageSeverity } from 'types/enum/MessageSeverity';
import {
  useCreateProjectMutation,
  useGetProjectsQuery,
} from 'services/project/projectSlice';
import {
  validateAlphaNumeric,
  validateAlphabets,
  validateExpression,
} from 'common/validationFunction';
import update from 'immutability-helper';
import routerPath from 'config/routerPath';
import { messages } from 'components/common/messages';
import { default as ProjectStatus } from './ProjectStatus';
import WithToast from 'components/HOC/WithToast';
import { IPropTypesToast } from 'types/ToastTypes';
import { Breadcrumb } from '@trimbleinc/modus-react-bootstrap';
import { sendCustomLog } from 'helpers/sdkCustomEventLogger';

const CreateViewProject = (props: IPropTypesToast) => {
  const Styles = {
    container: {
      height: '95vh',
    },
    innerContainer: {
      height: '63vh',
      overflow: 'auto',
    },
  };

  const params = useParams();
  const [createProject, createProjectData] = useCreateProjectMutation();
  const { isSuccess, data, isFetching } = useGetProjectsQuery();
  const navigate = useNavigate();
  const initialProjectDetails: ProjectTypes = {
    name: '',
    division: ProjectDivisionOption.SPIME,
    platform: ProjectTypeOption.ANDROID,
    languageVersion: '',
    language: ProjectLanguageOption.CPP,
    description: '',
    displayName: '',
    consumerKey: '',
    consumerSecret: '',
    applicationId: '',
    id: '',
    languageOthers: '',
    useSDK: true,
    trackDefaultEvents: false,
    divisionOthers: '',
  };

  const updateProjectDetails = (attribute: any): ProjectTypes => {
    return update(projectDetails, { $merge: attribute });
  };

  const [showClientId, setShowClientId] = React.useState(false);
  const [showClientSecret, setShowClientSecret] = React.useState(false);
  const [projectDetails, setProjectDetails] = React.useState<ProjectTypes>(
    initialProjectDetails
  );
  const [projectName, setProjectName] = useState('');
  const validateProjectDetails = (key: string, value: string) => {
    switch (key) {
      case 'projectName':
        if (validateExpression(value, RegExp('^[a-zA-Z0-9-]*$')))
          setProjectDetails(updateProjectDetails({ name: value }));
        break;
      case 'displayName':
        if (validateAlphaNumeric(value))
          setProjectDetails(updateProjectDetails({ displayName: value }));
        break;
      case 'languageVersion':
        if (validateExpression(value, RegExp('^[a-zA-Z0-9_. ]*$')))
          setProjectDetails(updateProjectDetails({ languageVersion: value }));
        break;
      case 'projectDescription':
        setProjectDetails(updateProjectDetails({ description: value }));
        break;
      case 'secondaryProjectType':
        if (validateAlphabets(value)) {
          setProjectDetails(
            updateProjectDetails({
              platform: value,
            })
          );
        }
        break;
      case 'secondaryProgrammingLanguage':
        if (validateExpression(value, RegExp('^[a-zA-Z#_.+ ]*$'))) {
          setProjectDetails(
            updateProjectDetails({
              languageOthers: value,
              language: value,
            })
          );
        }
        break;
      case 'secondaryDivisionName':
        if (validateExpression(value, RegExp('^[a-zA-Z ]*$'))) {
          setProjectDetails(
            updateProjectDetails({
              division: value,
              divisionOthers: value,
            })
          );
        }
        break;
      default:
        break;
    }
  };
  const getProjectData = async () => {
    if (isSuccess && !isFetching) {
      data.find((card) => {
        if (card.id === params.id) {
          setProjectName(card.name);
          setProjectDetails({
            ...card,
            language:
              Object.values(ProjectLanguageOption).indexOf(
                card.language as ProjectLanguageOption
              ) === -1
                ? 'Others'
                : card.language,
            languageOthers:
              Object.values(ProjectLanguageOption).indexOf(
                card.language as ProjectLanguageOption
              ) === -1
                ? card.language
                : projectDetails.languageOthers,
            languageVersion: card.languageVersion,
            platform:
              Object.values(ProjectTypeOption).indexOf(
                card.platform as ProjectTypeOption
              ) === -1
                ? 'Others'
                : card.platform,
            division:
              Object.values(ProjectDivisionOption).indexOf(
                card.division as ProjectDivisionOption
              ) === -1
                ? 'Others'
                : card.division,
            divisionOthers:
              Object.values(ProjectDivisionOption).indexOf(
                card.division as ProjectDivisionOption
              ) === -1
                ? card.division
                : projectDetails.divisionOthers,
          });
          return card;
        }
      });
    }
  };

  React.useEffect(() => {
    if (params.id) {
      getProjectData();
    }
  }, [params, isFetching]);

  React.useEffect(() => {
    if (createProjectData.isSuccess) {
      props.handleSetToastProps(
        messages.PROJECTDETAILS_SUCCESS,
        MessageSeverity.success
      );
      navigate(
        `/${routerPath.PRIVATE_ROUTE.projectPath}/${createProjectData.data.id}`
      );
    } else if (createProjectData.isError) {
      props.handleSetToastProps(
        (createProjectData.error as any)?.data?.message
          ? (createProjectData.error as any)?.data?.message
          : messages.PROJECTDETAILS_ERROR,
        MessageSeverity.error
      );
    }
  }, [createProjectData]);
  const validateProjectDetailsEmpty = (): boolean => {
    if (
      projectDetails.description.trim() &&
      projectDetails.displayName.trim() &&
      projectDetails.division &&
      projectDetails.language &&
      projectDetails.languageVersion.trim() &&
      projectDetails.name &&
      projectDetails.platform
    ) {
      if (projectDetails.language === 'Others') {
        if (!projectDetails.languageOthers.trim()) {
          return true;
        }
      }
      if (projectDetails.division === 'Others') {
        if (!projectDetails.divisionOthers.trim()) {
          return true;
        }
      }
      return false;
    }
    return true;
  };
  const handleSubmit = async () => {
    let response: any = await createProject(projectDetails);
    console.log('create project response::', response);
    sendCustomLog('CREATE_PROJECT_SUBMIT', 'PROJECT', {
      createdBy: response.data.createdBy,
      projectName: response.data.name,
    });
  };

  return createProjectData.isLoading || isFetching ? (
    <CenteredSpinnerCircle loadingText="Loading"></CenteredSpinnerCircle>
  ) : (
    <div style={{ overflowX: 'hidden' }}>
      <Form>
        <Container fluid>
          <Row>
            {params.id && (
              <Breadcrumb className="mt-2 ml-1">
                <Breadcrumb.Item
                  onClick={() => {
                    navigate(routerPath.PRIVATE_ROUTE.projectsHomePath);
                  }}
                >
                  Home
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {projectName ? projectName : 'Project Does Not Exist'}
                </Breadcrumb.Item>
              </Breadcrumb>
            )}
            <Col>
              <h1
                className={
                  params.id
                    ? 'h1 font-weight-bold col-md-2 col-xs-12'
                    : 'h1 mt-3 font-weight-bold col-md-2 col-xs-12'
                }
              >
                {params.id ? 'Project Info' : 'Create Project'}
              </h1>
            </Col>
          </Row>
          <Row style={{ height: '70vh' }}>
            <Col className="m-3" style={Styles.innerContainer}>
              <FormField
                disabled={params.id ? true : false}
                type="text"
                value={projectDetails.name}
                label="Project Name"
                placeholderText="Enter Project Name"
                controlId="projectName"
                maxLength={25}
                handleInput={({ id, value }) => {
                  validateProjectDetails(id, value);
                }}
              ></FormField>
              <FormField
                disabled={params.id ? true : false}
                type="text"
                maxLength={25}
                value={projectDetails.displayName}
                label="Display Name"
                placeholderText="Enter Display Name"
                controlId="displayName"
                handleInput={({ id, value }) => {
                  validateProjectDetails(id, value);
                }}
              ></FormField>
              {params.id ? (
                <FormField
                  disabled={params.id ? true : false}
                  type="text"
                  maxLength={64}
                  value={
                    projectDetails.divisionOthers
                      ? projectDetails.divisionOthers
                      : projectDetails.division
                  }
                  label="Project Division"
                  controlId="projectDivision"
                ></FormField>
              ) : (
                <FormDropDown
                  disabled={params.id ? true : false}
                  controlId="projectDivision"
                  defaultValue={
                    projectDetails.divisionOthers
                      ? 'Others'
                      : projectDetails.division
                  }
                  value={
                    projectDetails.divisionOthers
                      ? 'Others'
                      : projectDetails.division
                  }
                  label="Project Division"
                  options={Object.values(ProjectDivisionOption)}
                  handleInput={(value) => {
                    setProjectDetails(
                      updateProjectDetails({
                        division: value,
                      })
                    );
                    if (value !== 'Others')
                      setProjectDetails(
                        updateProjectDetails({
                          division: value,
                          divisionOthers: '',
                        })
                      );
                  }}
                ></FormDropDown>
              )}
              {!params.id &&
                (projectDetails.division === 'Others' ||
                  projectDetails.divisionOthers) && (
                  <div className="mt-n4">
                    <FormField
                      disabled={params.id ? true : false}
                      maxLength={15}
                      type="text"
                      value={projectDetails.divisionOthers}
                      label=""
                      placeholderText="Enter Division Name"
                      controlId="secondaryDivisionName"
                      handleInput={({ id, value }) => {
                        validateProjectDetails(id, value);
                      }}
                    ></FormField>
                  </div>
                )}
              <FormRadioButton
                disabled={params.id ? true : false}
                value={
                  projectDetails.useSDK
                    ? ProjectUseSDKRestApi.SDK
                    : ProjectUseSDKRestApi.REST_API
                }
                label="Use"
                Id="projectuse"
                option={Object.values(ProjectUseSDKRestApi)}
                handleInput={({ id }) => {
                  !params.id &&
                    setProjectDetails(
                      updateProjectDetails({
                        useSDK: id === ProjectUseSDKRestApi.SDK,
                      })
                    );
                }}
                tooltipRequired={true}
                tooltipText="Trimble Insights REST APIs or the SDKs can be leveraged
                to start ingesting data. SDKs are available for C++, C#,
                Javascript and Kotlin."
              ></FormRadioButton>
              <FormRadioButton
                disabled={params.id ? true : false}
                value={
                  projectDetails.trackDefaultEvents
                    ? ProjectTrackDefaultEventsOption.YES
                    : ProjectTrackDefaultEventsOption.NO
                }
                label="Do you want to track battery,session and network usage?"
                Id="trackDefaultEvents"
                option={Object.values(ProjectTrackDefaultEventsOption)}
                handleInput={({ id }) => {
                  !params.id &&
                    setProjectDetails(
                      updateProjectDetails({
                        trackDefaultEvents:
                          id === ProjectTrackDefaultEventsOption.YES,
                      })
                    );
                }}
                tooltipRequired={true}
                tooltipText="Tracking these default events adds to the cost, as billing is based on the number of ingested events."
              ></FormRadioButton>
              {params.id && (
                <div>
                  <FormField
                    disabled={true}
                    type="text"
                    value={projectDetails.applicationId}
                    label="Application Id"
                    controlId="applicationId"
                  ></FormField>
                  <FormField
                    disabled={true}
                    type="password"
                    value={projectDetails.consumerKey}
                    label="Consumer Key"
                    controlId="ConsumerKey"
                    showPass={showClientId}
                  >
                    <RenderTooltip overlayText="Copy to clipboard">
                      <div>
                        <CopyToClipboard
                          text={projectDetails.consumerKey}
                          onCopy={() => {
                            props.handleSetToastProps(
                              messages.COPY_CONSUMER_KEY,
                              MessageSeverity.success
                            );
                          }}
                        >
                          <Button
                            variant="outline-secondary"
                            className="h-100"
                            onClick={() => {
                              sendCustomLog(
                                'CONSUMER_KEY_ACTIVITY',
                                'PROJECT_INFO',
                                {
                                  action: 'COPY',
                                  projectName: projectDetails.name,
                                }
                              );
                            }}
                          >
                            <ClipboardData size={20}></ClipboardData>
                          </Button>
                        </CopyToClipboard>
                      </div>
                    </RenderTooltip>
                    <RenderTooltip
                      overlayText={
                        showClientId ? 'Hide Consumer Key' : 'Show Consumer Key'
                      }
                    >
                      <Button
                        variant="outline-secondary"
                        onClick={() => {
                          sendCustomLog(
                            'CONSUMER_KEY_ACTIVITY',
                            'PROJECT_INFO',
                            {
                              action: showClientId ? 'HIDE' : 'SHOW',
                              projectName: projectDetails.name,
                            }
                          );
                          setShowClientId((prev) => !prev);
                        }}
                      >
                        {showClientId ? (
                          <EyeFill size={20}></EyeFill>
                        ) : (
                          <EyeSlashFill size={20}></EyeSlashFill>
                        )}
                      </Button>
                    </RenderTooltip>
                  </FormField>
                  <FormField
                    disabled={true}
                    type="password"
                    value={projectDetails.consumerSecret}
                    label="Consumer Secret"
                    controlId="ConsumerSecret"
                    showPass={showClientSecret}
                  >
                    <RenderTooltip overlayText="Copy to clipboard">
                      <div>
                        <CopyToClipboard
                          text={projectDetails.consumerSecret}
                          onCopy={() => {
                            props.handleSetToastProps(
                              messages.COPY_CONSUMER_SECRET,
                              MessageSeverity.success
                            );
                          }}
                        >
                          <Button
                            variant="outline-secondary"
                            className="h-100"
                            onClick={() => {
                              sendCustomLog(
                                'CONSUMER_SECRET_ACTIVITY',
                                'PROJECT_INFO',
                                {
                                  action: 'COPY',
                                  projectName: projectDetails.name,
                                }
                              );
                            }}
                          >
                            <ClipboardData size={20}></ClipboardData>
                          </Button>
                        </CopyToClipboard>
                      </div>
                    </RenderTooltip>
                    <RenderTooltip
                      overlayText={
                        showClientSecret
                          ? 'Hide Consumer Secret'
                          : 'Show Consumer Secret'
                      }
                    >
                      <Button
                        variant="outline-secondary"
                        onClick={() => {
                          sendCustomLog(
                            'CONSUMER_SECRET_ACTIVITY',
                            'PROJECT_INFO',
                            {
                              action: showClientSecret ? 'HIDE' : 'SHOW',
                              projectName: projectDetails.name,
                            }
                          );
                          setShowClientSecret((prev) => !prev);
                        }}
                      >
                        {showClientSecret ? (
                          <EyeFill size={20}></EyeFill>
                        ) : (
                          <EyeSlashFill size={20}></EyeSlashFill>
                        )}
                      </Button>
                    </RenderTooltip>
                  </FormField>
                </div>
              )}
              {params.id ? (
                <FormField
                  disabled={params.id ? true : false}
                  type="text"
                  maxLength={64}
                  value={projectDetails.platform}
                  label="Project Type"
                  controlId="projectType"
                ></FormField>
              ) : (
                <FormDropDown
                  disabled={params.id ? true : false}
                  controlId="projectType"
                  value={projectDetails.platform}
                  label="Project Type"
                  defaultValue={projectDetails.platform}
                  options={Object.values(ProjectTypeOption)}
                  handleInput={(value) => {
                    setProjectDetails(
                      updateProjectDetails({
                        platform: value,
                      })
                    );
                  }}
                ></FormDropDown>
              )}

              {params.id ? (
                <FormField
                  disabled={params.id ? true : false}
                  type="text"
                  maxLength={64}
                  value={
                    projectDetails.languageOthers
                      ? projectDetails.languageOthers
                      : projectDetails.language
                  }
                  label="Programming Language"
                  controlId="programmingLanguage"
                ></FormField>
              ) : (
                <FormDropDown
                  disabled={params.id ? true : false}
                  controlId="programmingLanguage"
                  value={
                    projectDetails.languageOthers
                      ? 'Others'
                      : projectDetails.language
                  }
                  defaultValue={
                    projectDetails.languageOthers
                      ? 'Others'
                      : projectDetails.language
                  }
                  label="Programming Language"
                  options={Object.values(ProjectLanguageOption)}
                  handleInput={(value) => {
                    setProjectDetails(
                      updateProjectDetails({
                        language: value,
                      })
                    );
                    if (value !== 'Others')
                      setProjectDetails(
                        updateProjectDetails({
                          language: value,
                          languageOthers: '',
                        })
                      );
                  }}
                ></FormDropDown>
              )}
              {!params.id &&
                (projectDetails.language === 'Others' ||
                  projectDetails.languageOthers) && (
                  <div className="mt-n4">
                    <FormField
                      disabled={params.id ? true : false}
                      maxLength={64}
                      type="text"
                      value={projectDetails.languageOthers}
                      label=""
                      placeholderText="Enter Programming Language"
                      controlId="secondaryProgrammingLanguage"
                      handleInput={({ id, value }) => {
                        validateProjectDetails(id, value);
                      }}
                    ></FormField>
                  </div>
                )}
              <FormField
                disabled={params.id ? true : false}
                type="text"
                maxLength={64}
                value={projectDetails.languageVersion}
                label="Language Version"
                placeholderText="Enter Language Version"
                controlId="languageVersion"
                handleInput={({ id, value }) => {
                  validateProjectDetails(id, value);
                }}
              ></FormField>
              <FormTextArea
                disabled={params.id ? true : false}
                maxLength={100}
                row={2}
                value={projectDetails.description}
                label="Project Description"
                controlId="projectDescription"
                handleInput={(id, value) => {
                  validateProjectDetails(id, value);
                }}
              ></FormTextArea>
              <div className="mt-n4">{params.id && <ProjectStatus />}</div>
            </Col>
          </Row>
          {!params.id && (
            <Row>
              <Col>
                <div className="text-right mb-4">
                  <Button
                    type="button"
                    disabled={validateProjectDetailsEmpty()}
                    onClick={() => handleSubmit()}
                  >
                    Submit
                  </Button>
                  <Button
                    type="reset"
                    className="ml-4"
                    onClick={() => {
                      setProjectDetails(initialProjectDetails);
                    }}
                  >
                    Reset
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </Form>
    </div>
  );
};

const CreateViewProjectWithToast = WithToast(CreateViewProject);

export default CreateViewProjectWithToast;

import React, { useState } from 'react';
import {
  Button,
  Card,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
} from '@trimbleinc/modus-react-bootstrap';
import { FormDropDown } from 'components/common/FormDropDown';
import { RuleCondition, RuleType, TriggerPointType } from 'types/enum/NPSEnum';
import { IPropTypesConfigurationsNPSForm } from 'types/ConfigurationsNPSFormTypes';
import { messages } from 'components/common/messages';
import { MessageSeverity } from 'types/enum/MessageSeverity';

const Styles = {
  formLabel: {
    fontSize: '14px',
  },
  formContent: {
    fontSize: '16px',
    height: '50px',
  },
};
export const NPSForm = (props: IPropTypesConfigurationsNPSForm) => {
  const {
    NPSFormValues,
    handleNPSFormValues,
    removeFieldsNPS,
    handleSetToastProps,
  } = props;
  const [durationType, setDurationType] = useState(TriggerPointType.IN_DAYS);
  return (
    <>
      {NPSFormValues.map((formValue, idx) => {
        return (
          <div className="d-flex justify-content-center" key={idx}>
            <Card
              style={{ width: '60rem' }}
              className="border border-secondary mt-1 rounded-2"
            >
              <Card.Body>
                <div>
                  <Form.Group className="mb-4" controlId="eventName">
                    <Form.Label
                      className=" text-secondary"
                      style={Styles.formLabel}
                    >
                      Event Name
                    </Form.Label>

                    <InputGroup>
                      <Form.Control
                        disabled={false}
                        plaintext={false}
                        style={Styles.formContent}
                        required
                        value={formValue.eventName}
                        placeholder="Enter Event Name"
                        size="lg"
                        onChange={(e) => {
                          handleNPSFormValues(
                            idx,
                            'eventName',
                            e.target.value.trim()
                          );
                        }}
                      ></Form.Control>
                    </InputGroup>
                  </Form.Group>
                </div>
                <FormDropDown
                  disabled={false}
                  controlId="ruleType"
                  defaultValue={'Count'}
                  value={formValue.ruleType ? formValue.ruleType : 'count'}
                  label="Rule Type"
                  options={Object.values(RuleType)}
                  handleInput={(value) => {
                    handleNPSFormValues(idx, 'ruleType', value);
                  }}
                ></FormDropDown>
                <FormDropDown
                  disabled={false}
                  controlId="ruleCondition"
                  defaultValue={'once every'}
                  value={
                    formValue.ruleCondition
                      ? formValue.ruleCondition
                      : 'once every'
                  }
                  label="Rule Condition"
                  options={Object.values(RuleCondition)}
                  handleInput={(value) => {
                    handleNPSFormValues(idx, 'ruleCondition', value);
                  }}
                ></FormDropDown>
                <div>
                  <Form.Group className="mb-4" controlId="triggerPoint">
                    <Form.Label
                      className=" text-secondary"
                      style={Styles.formLabel}
                    >
                      Trigger Point
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        disabled={false}
                        plaintext={false}
                        style={Styles.formContent}
                        required
                        value={
                          formValue.triggerPoint
                            ? parseInt(formValue.triggerPoint)
                            : ''
                        }
                        placeholder="Enter Trigger Point"
                        size="lg"
                        type="number"
                        onChange={(e) => {
                          let value = e.target.value.trim();
                          if (parseInt(value) <= 0) {
                            handleSetToastProps(
                              formValue.ruleType == RuleType.COUNT
                                ? messages.COUNT_TRIGGER_POINT_ERROR
                                : messages.DURATION_TRIGGER_POINT_ERROR,
                              MessageSeverity.warning
                            );
                            return;
                          }
                          handleNPSFormValues(
                            idx,
                            'triggerPoint',
                            formValue.ruleType == RuleType.DURATIONINDAYS
                              ? value + 'D'
                              : value
                          );
                        }}
                      ></Form.Control>
                      {formValue.ruleType == RuleType.DURATIONINDAYS && (
                        <DropdownButton
                          variant="outline-secondary"
                          id="dropdown-basic-button-1"
                          title={durationType ? durationType : 'Duration'}
                          className="ml-0"
                          // style={{ height: '50px', width: '105.36px' }}
                        >
                          <Dropdown.Item
                            onSelect={() => {
                              setDurationType(TriggerPointType.IN_DAYS);
                            }}
                          >
                            {TriggerPointType.IN_DAYS}
                          </Dropdown.Item>
                        </DropdownButton>
                      )}
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="d-flex flex-row-reverse">
                  {NPSFormValues.length > 1 && (
                    <Button
                      variant="text-danger"
                      onClick={() => removeFieldsNPS(idx)}
                    >
                      Delete
                    </Button>
                  )}
                </div>
              </Card.Body>
            </Card>
          </div>
        );
      })}
    </>
  );
};

import React from 'react';
import { Router } from 'components/oauth/Router';
import { Provider } from 'react-redux';
import { store } from 'services/store/store';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
export const App = () => {
  return (
    <Provider store={store}>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<Router />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
};

import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import config from 'config';
import { setHeaders } from 'services/apiHeaders';
import { CustomError } from 'types/CustomErrorTypes';

export const configurationSlice = createApi({
  reducerPath: 'configurationSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: config.TRIMBLE_INSIGHTS_PROJECTS_SERVICE.BASE_URL,
    prepareHeaders(headers) {
      return setHeaders(headers);
    },
  }) as BaseQueryFn<string | FetchArgs, unknown, CustomError, {}>,
  refetchOnMountOrArgChange: true,
  tagTypes: ['Configurations'],
  endpoints: (builder) => ({
    getConfiguration: builder.query<
      any,
      {
        id: string;
        configType: string;
      }
    >({
      query: (arg) => {
        let { id, configType } = arg;
        return {
          url: `projects/${id}/configuration/${configType}`,
        };
      },
      providesTags: ['Configurations'],
    }),
    createConfiguration: builder.mutation<
      any,
      {
        id: string;
        configType: string;
        body: object;
      }
    >({
      query(arg) {
        let { id, configType, body } = arg;
        return {
          url: `projects/${id}/configuration/${configType}`,
          method: 'PUT',
          body: JSON.stringify(body),
        };
      },
      invalidatesTags: ['Configurations'],
    }),
  }),
});

export const { useGetConfigurationQuery, useCreateConfigurationMutation } =
  configurationSlice;

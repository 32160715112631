import React from 'react';

interface Column {
  dataField: string;
  text: string;
  style?: React.CSSProperties;
}

export interface ISelectRowProps {
  mode: 'checkbox' | 'radio';
  hideSelectAll?: boolean;
  clickToSelect?: boolean;
  selected?: any[];
  onSelect?: (row: any, isSelected: boolean) => void;
  onSelectAll?: (rows: any[], isSelected: boolean) => void;
}

interface Props {
  data: any[];
  columns: Column[];
  selectRowProps?: ISelectRowProps;
  keyField: string;
  selectedRows?: any;
  rowStyle?: React.CSSProperties;
}

const CustomTable = (props: Props) => {
  const { data, columns, keyField } = props;
  return (
    <table className="table table-striped table-bordered">
      <thead>
        <tr>
          {props.selectRowProps &&
          props.selectRowProps.mode === 'checkbox' &&
          !props.selectRowProps.hideSelectAll ? (
            <th className="selection-cell-header">
              <input
                type="checkbox"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (
                    props.selectRowProps &&
                    props.selectRowProps.onSelectAll
                  ) {
                    return props.selectRowProps.onSelectAll(
                      props.selectedRows,
                      e.target.checked
                    );
                  }
                }}
                checked={props.selectedRows.length === data.length}
              />
            </th>
          ) : (
            props.selectRowProps &&
            props.selectRowProps.mode === 'checkbox' && (
              <th className="selection-cell-header"></th>
            )
          )}
          {columns.map((column) => (
            <th key={column.dataField}>{column.text}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row) => {
          return (
            <tr key={row[keyField]} style={props.rowStyle}>
              {props.selectRowProps &&
                props.selectRowProps.mode === 'checkbox' && (
                  <td className="selection-cell">
                    <input
                      type="checkbox"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (
                          props.selectRowProps &&
                          props.selectRowProps.onSelect
                        ) {
                          props.selectRowProps.onSelect(row, e.target.checked);
                        }
                      }}
                      checked={props.selectedRows.includes(row[keyField])}
                    />
                  </td>
                )}
              {columns.map((column) => (
                <td key={column.dataField} style={column.style}>
                  {row[column.dataField]}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default CustomTable;

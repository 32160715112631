import React from 'react';
import { Layout } from 'components/oauth/Layout';
import { RequireAuth } from 'components/oauth/RequireAuth';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Error } from '../common/Error';
import { Logout } from './Logout';
import { default as Home } from 'components/Projects/Projects';
import { TidLogin } from 'components/oauth/TidLogin';
import { AuthToken } from 'components/oauth/AuthToken';
import RouterPath from 'config/routerPath';
import { default as CreateViewProject } from 'components/Projects/CreateProject';
import { Data } from 'components/Data';
import { default as Executions } from 'components/Executions';
import { PageNotFound } from 'components/PageNotFound';
import { default as Users } from 'components/Users/Users';
import { Documentation } from 'components/Docs/Documentation';
import { default as ProjectConfigurations } from 'components/Project-Configuration/ProjectConfigurations';
export const Router = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route
          path={RouterPath.PUBLIC_ROUTE.loginTIDPath}
          element={<TidLogin />}
        ></Route>
        <Route
          path={RouterPath.PUBLIC_ROUTE.errorUrlPath}
          element={<Error />}
        ></Route>
        <Route
          path={RouterPath.PUBLIC_ROUTE.logoutPath}
          element={<Logout />}
        ></Route>
        <Route
          path={RouterPath.PUBLIC_ROUTE.redirectUrlPath}
          element={<AuthToken />}
        ></Route>
        <Route
          path={RouterPath.PUBLIC_ROUTE.documentationPath}
          element={<Documentation />}
        ></Route>
        <Route element={<RequireAuth />}>
          <Route
            path={RouterPath.PRIVATE_ROUTE.projectsHomePath}
            element={<Home />}
          ></Route>
          <Route
            path={`${RouterPath.PRIVATE_ROUTE.projectPath}/:id`}
            element={<CreateViewProject />}
          ></Route>
          <Route
            path={RouterPath.PRIVATE_ROUTE.createProjectPath}
            element={<CreateViewProject />}
          ></Route>
          <Route
            path={RouterPath.PRIVATE_ROUTE.dataPath}
            element={<Data />}
          ></Route>
          <Route
            path={RouterPath.PRIVATE_ROUTE.executionPath}
            element={<Executions />}
          ></Route>
          <Route
            path={RouterPath.PRIVATE_ROUTE.configurationPath}
            element={<ProjectConfigurations />}
          ></Route>
          <Route
            path={RouterPath.PRIVATE_ROUTE.notFound}
            element={<PageNotFound />}
          ></Route>
          <Route
            path={RouterPath.PRIVATE_ROUTE.usersPath}
            element={<Users />}
          ></Route>
          <Route
            path="*"
            element={
              <Navigate
                to={RouterPath.PRIVATE_ROUTE.projectsHomePath}
                replace
              ></Navigate>
            }
          ></Route>
        </Route>
      </Route>
    </Routes>
  );
};

import React from 'react';
import { useNavigate } from 'react-router-dom';
import RouterPath from 'config/routerPath';
import 'styles/NotFound404.css';
import { Button } from '@trimbleinc/modus-react-bootstrap';
export const PageNotFound = () => {
  let navigate = useNavigate();
  return (
    <div className="centeredElement d-flex flex-column">
      <div className="h1 font-weight-bold errorCode d-flex justify-content-center">
        4<span className="text-trimble-blue">0</span>4
      </div>
      <div className="h3 font-weight-light d-flex justify-content-center errorText mt-md-2">
        Page Not Found.
      </div>
      <Button
        variant="text-primary"
        className="d-flex justify-content-center mt-md-3"
        onClick={() => navigate(RouterPath.PRIVATE_ROUTE.projectsHomePath)}
        block
      >
        <span className="homeButton">Home Page</span>
      </Button>
    </div>
  );
};

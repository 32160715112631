const routerPath = {
  PUBLIC_ROUTE: {
    loginTIDPath: 'TIDLogin',
    redirectUrlPath: 'redirect',
    errorUrlPath: 'error',
    logoutPath: 'logout',
    documentationPath: 'docs',
  },
  PRIVATE_ROUTE: {
    notFound: '/notfound',
    projectPath: 'projects',
    createProjectPath: 'create',
    projectsHomePath: '/',
    dataPath: '/data',
    executionPath: '/executions',
    configurationPath: '/configurations',
    usersPath: 'users',
  },
};

export default routerPath;

import { Button } from '@trimbleinc/modus-react-bootstrap';
import React from 'react';
import { NodePlus } from 'react-bootstrap-icons';
import { IPropTypesExecutionHeader } from 'types/ExecutionHeaderTypes';

export const ExecutionsHeader = (props: IPropTypesExecutionHeader) => {
  return (
    <div className="d-flex flex-md-row flex-sm-column flex-xs-column row">
      <div className="col-6">
        <div className="h1 font-weight-bold">Executions</div>
      </div>
      <div className="col-6">
        <div className="d-flex justify-content-start justify-content-md-end">
          {/* "Create Execution" button should be disabled if the get executions list api call's "isLoading" value is true or if there is an error in fetching the executions list. */}
          <Button
            disabled={
              props.isErrorInGetExecutionsList ||
              props.isLoadingGetExecutionsList 
                ? true
                : false
            }
            variant="light"
            className={
              props.isLoadingCreation || props.isUpdatingExecutions
                ? 'btn mx-1 mx-sm-1 border-primary rounded shadow disabled'
                : 'btn mx-1 mx-sm-1 border-primary rounded shadow'
            }
            onClick={(e) => {
              props.handleCreateExecutionBtnClick(e);
            }}
          >
            <NodePlus className="createExecutionIcon" />
            <span className="ml-3">Create Execution</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useGetProjectsQuery,
  useGetProjectStatusQuery,
} from 'services/project/projectSlice';
import RouterPath from 'config/routerPath';
import { IPropTypesRouteValidations } from 'types/RouteValidationsTypes';
import { ProjectStatusOption } from 'types/enum/ProjectEnum';
import { CenteredSpinnerCircle } from 'components/common/CenteredSpinnerCircle';

function WithRouteValidation<TProps>(
  WrappedComponent: React.FC<TProps & IPropTypesRouteValidations>
) {
  function NewComponent(props: TProps) {
    const navigate = useNavigate();
    let location = useLocation();
    const [projectId, setProjectId] = useState('');
    const [showProjectSpecificNavItems, setShowProjectSpecificNavItems] =
      useState(false);
    const { data: allProjectDetails, isFetching: fetchingAllProjects } =
      useGetProjectsQuery();
    const checkIsProjectIdValid = (pId: string) => {
      if (pId && allProjectDetails && allProjectDetails.length > 0) {
        let isProjectIdValid = allProjectDetails?.find(
          (project) => project.id == pId
        );
        if (!isProjectIdValid) {
          navigate(RouterPath.PRIVATE_ROUTE.notFound);
          return;
        }
      }
    };
    useEffect(() => {
      if (!fetchingAllProjects) {
        if (location.pathname.startsWith('/projects')) {
          if (location.pathname.trim().split('/')[2]) {
            let pId = location.pathname.trim().split('/')[2];
            setProjectId(location.pathname.trim().split('/')[2]);
            checkIsProjectIdValid(pId);
          }
        } else if (
          location.pathname == '/data' ||
          location.pathname == '/executions' ||
          location.pathname == '/users' ||
          location.pathname == '/configurations'
        ) {
          if (location.search.startsWith('?projectId')) {
            setProjectId(location.search.split('?projectId=')[1]);
            checkIsProjectIdValid(location.search.split('?projectId=')[1]);
            setShowProjectSpecificNavItems(true);
          } else {
            navigate(RouterPath.PRIVATE_ROUTE.notFound);
            return;
          }
        } else {
          setShowProjectSpecificNavItems(false);
          setProjectId('');
        }
      }
    }, [location, fetchingAllProjects]);

    const [projectName, setProjectName] = useState('');
    useEffect(() => {
      let projects = allProjectDetails;
      projects?.find((project) => {
        if (project.id == projectId) {
          setProjectName(project.name);
        }
      });
    }, [projectId]);
    const [status, setStatus] = useState<ProjectStatusOption>();
    // Here, the status of the Project(based on the project id) is polled,
    // which yields results suchs as "FAILED" or "SUCCEEDED".
    // Only if the status of the Project is "SUCCEEDED", project specific Nav Items(Home,Data,Executions)
    // will be displayed.
    const {
      isSuccess: isStatusSuccess,
      data: projectStatusData,
      isFetching: fetchingProjectStatus,
      isError: isStatusError,
      refetch,
    } = useGetProjectStatusQuery(projectId, { skip: projectId ? false : true });
    useEffect(() => {
      if (!fetchingProjectStatus) {
        if (isStatusSuccess) {
          let projectStatusOptionArray = Object.values(ProjectStatusOption);
          if (projectStatusOptionArray.includes(projectStatusData.status)) {
            setStatus(projectStatusData.status);
          } else {
            setStatus(ProjectStatusOption.UNKNOWN);
          }
          setShowProjectSpecificNavItems(true);
        }
      }
    }, [isStatusSuccess, fetchingProjectStatus]);
    useEffect(() => {
      if (isStatusError) {
        navigate(RouterPath.PRIVATE_ROUTE.notFound);
      }
    }, [isStatusError]);
    useEffect(() => {
      if (projectId != location.search.split('?projectId=')[1]) {
        projectId && refetch();
      }
    }, [projectId]);

    // showProjectSpecificNavItems holds the value of "true" if and only if
    // necessary conditions have been validated and thus can be used
    // for conditional rendering, if conditions were not met at any point,
    // redirection to "/notfound" page would have been done.
    return showProjectSpecificNavItems ? (
      <>
        <WrappedComponent
          allProjectDetails={allProjectDetails}
          projectStatus={status}
          projectName={projectName}
          {...(props as TProps)}
        />
      </>
    ) : fetchingProjectStatus ? (
      <CenteredSpinnerCircle loadingText="Loading Project Status"></CenteredSpinnerCircle>
    ) : (
      <CenteredSpinnerCircle loadingText="Loading"></CenteredSpinnerCircle>
    );
  }
  return NewComponent;
}

export default WithRouteValidation;

import React from 'react';
import {
  Button,
  Card,
  Form,
  InputGroup,
} from '@trimbleinc/modus-react-bootstrap';
import { FormDropDown } from 'components/common/FormDropDown';
import { ActionType } from 'types/enum/PIIEnum';
import { IPropTypesConfigurationsPIIForm } from 'types/ConfigurationsPIIFormTypes';
const Styles = {
  formLabel: {
    fontSize: '14px',
  },
  formContent: {
    fontSize: '16px',
    height: '50px',
  },
};
export const PIIForm = (props: IPropTypesConfigurationsPIIForm) => {
  const { PIIFormValues, handlePIIFormValues, removeFieldsPII } = props;
  return (
    <>
      {PIIFormValues.map((formValue, idx) => {
        return (
          <>
            <div className="d-flex justify-content-center" key={idx}>
              <Card
                style={{ width: '60rem' }}
                className="border border-secondary mt-1 rounded-2"
                key={idx}
              >
                <Card.Body>
                  <div>
                    <Form.Group className="mb-4" controlId="attributeName">
                      <Form.Label
                        className=" text-secondary"
                        style={Styles.formLabel}
                      >
                        Attribute Name
                      </Form.Label>

                      <InputGroup>
                        <Form.Control
                          disabled={false}
                          plaintext={false}
                          style={Styles.formContent}
                          required
                          value={
                            formValue.attributeName
                              ? formValue.attributeName
                              : formValue.attributeName
                          }
                          placeholder="Enter Attribute Name"
                          size="lg"
                          onChange={(e) => {
                            handlePIIFormValues(
                              idx,
                              'attributeName',
                              e.target.value.trim()
                            );
                          }}
                        ></Form.Control>
                      </InputGroup>
                    </Form.Group>
                  </div>
                  <FormDropDown
                    disabled={false}
                    controlId="pii_action"
                    defaultValue={'hash'}
                    value={formValue.pii_action ? formValue.pii_action : 'hash'}
                    label="PII Action"
                    options={Object.values(ActionType)}
                    handleInput={(value) => {
                      handlePIIFormValues(idx, 'pii_action', value);
                    }}
                  ></FormDropDown>

                  <div className="d-flex flex-row-reverse">
                    {PIIFormValues.length > 1 && (
                      <Button
                        variant="text-danger"
                        onClick={() => removeFieldsPII(idx)}
                      >
                        Delete
                      </Button>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </div>
          </>
        );
      })}
    </>
  );
};

import { Dropdown } from '@trimbleinc/modus-react-bootstrap';
import { messages } from 'components/common/messages';
import {
  getDefaultEndDate,
  getDefaultStartDate,
} from 'helpers/defaultDatesHeaders';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { IPropTypesDateFilter } from 'types/DateFilterTypes';
import { MessageSeverity } from 'types/enum/MessageSeverity';
import { IPropTypesToast } from 'types/ToastTypes';

interface IPropTypesWrapperDatesFilter
  extends IPropTypesToast,
    IPropTypesDateFilter {}

export const DateFilter = (props: IPropTypesWrapperDatesFilter) => {
  let [dates, setDates] = useState({
    startDate: getDefaultStartDate(),
    endDate: getDefaultEndDate(),
  });
  const handleChange = (name: any) => (event: any) => {
    props.handleSetToastProps('', '');
    // CASE 1: startDate is greater than today(current date).
    if (name == 'startDate') {
      let now = new Date();
      let typeCastedDate = new Date(event.target.value);
      if (now < typeCastedDate) {
        props.handleSetToastProps(
          messages.INVALID_START_DATE,
          MessageSeverity.warning
        );
        return;
      }
    }
    // CASE 2: After providing a valid start date, if the
    // end date is smaller than startDate.
    if (name == 'endDate' && dates.startDate) {
      let typeCastedDate = new Date(event.target.value);
      if (new Date(dates.startDate) > typeCastedDate) {
        props.handleSetToastProps(
          messages.INVALID_DATE_RANGE,
          MessageSeverity.warning
        );
        return;
      }
    }
    // CASE 3: Providing and end date when no start date is provided.
    // NOTE: If the onChange action for end date from date picker is "Clear",
    // then the event.target.value is null, so CASE 3 should fail in that case.
    if (name == 'endDate' && !dates.startDate && event.target.value) {
      props.handleSetToastProps(
        messages.MISSING_START_DATE,
        MessageSeverity.warning
      );
      return;
    }
    setDates({ ...dates, [name]: event.target.value });
  };

  const applyDateFilter = (e: any) => {
    if (props.onApplyClickCallback) {
      e.preventDefault();
      if(!dates.endDate)
      {
        props.handleSetToastProps(
          messages.MISSING_END_DATE,
          MessageSeverity.warning
        );
      }
      // CASE: If the provided start date and end date from the date picker,
      // is same as the default start and end date
      if (
        new Date(dates.startDate).valueOf() ==
          new Date(getDefaultStartDate()).valueOf() &&
        new Date(dates.endDate).valueOf() ==
          new Date(getDefaultEndDate()).valueOf()
      ) {
        props.onApplyClickCallback(dates.startDate, dates.endDate);
        return;
      }

      // CASE: If the provided start date from the date picker,
      // is same as the default start date
      if (
        !dates.endDate &&
        new Date(dates.startDate).valueOf() ==
          new Date(getDefaultStartDate()).valueOf()
      ) {
        props.onApplyClickCallback(dates.startDate, dates.endDate);
        return;
      }

      // CASE: start date is greater than end date
      if (
        !dates.startDate ||
        new Date(dates.startDate) > new Date(dates.endDate)
      ) {
        props.handleSetToastProps(
          messages.INVALID_DATE_RANGE,
          MessageSeverity.warning
        );
        return;
      }
      // CASE: If all validations cleared, and a start date is provided, it is set to it's corresponding state
      else if (dates.startDate && !dates.endDate) {
        props.onApplyClickCallback(dates.startDate, dates.endDate);
      } else if (dates.startDate && dates.endDate) {
        props.onApplyClickCallback(dates.startDate, dates.endDate);
      } else {
        props.onApplyClickCallback(dates.startDate, dates.endDate);
      }
    }
  };
  return (
    <>
      <p className="mt-md-2 h4 mx-1 font-weight-light">Start Date:</p>
      <input
        type="date"
        id="start"
        name="startDate"
        value={dates.startDate ? dates.startDate : ''}
        className={dates.startDate ? 'mb-3 mx-1' : 'mb-3 mx-1 text-muted'}
        onChange={handleChange('startDate')}
        max={getDefaultEndDate()}
      ></input>

      <p className="mt-md-2 h4 mx-1 font-weight-light">End Date:</p>
      <input
        type="date"
        id="end"
        name="endDate"
        value={dates.endDate ? dates.endDate : ''}
        className={dates.endDate ? 'mb-3 mx-1' : 'mb-3 mx-1 text-muted'}
        onChange={handleChange('endDate')}
        min={dates?.startDate ? dates.startDate : getDefaultStartDate()}
      ></input>
      <Dropdown>
        <Dropdown.Toggle
        variant="light"
        className="border-primary rounded shadow mx-1 mx-sm-1 btn mb-3 regionDropdown"
        id="dropdown-basic"
        >{props.region ? props.region : 'NON-EU'}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              props.handleSetRegion('EU');
            }}
            >
            EU
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.handleSetRegion('NON-EU');
            }}
            >
            NON-EU
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Button
        variant="light"
        className="mb-3 mx-1 mx-sm-1 border-primary rounded shadow"
        onClick={(e) => applyDateFilter(e)}
      >
        <span className="font-size-light">Apply</span>
      </Button>
    </>
  );
};

import React from 'react';
import { CenteredSpinnerCircle } from 'components/common/CenteredSpinnerCircle';
import { Navigate, useNavigate } from 'react-router-dom';
import { useGetTokenMutation } from 'services/oauth/authTokenSlice';
import jwt from 'jwt-decode';
import {
  storeLocalData,
  getLocalData,
  clearLocalData,
  localConstants,
} from 'components/storage/localStorage';
import { addSeconds } from '../../common/commonFunction';
import {
  sessionConstants,
  storeSessionData,
} from 'components/storage/sessionStorage';
import routerPath from 'config/routerPath';
import InitLogger from 'InitLogger';

export const AuthToken = () => {
  const navigate = useNavigate();
  const getParams = new URLSearchParams(window.location.search);
  const code = getParams.get('code');
  const [token, setToken] = React.useState('');
  const [getToken, tokenData] = useGetTokenMutation();
  let checkHandle = true;

  const handleToken = async () => {
    checkHandle = false;
    const code = getLocalData(localConstants.code);
    const codeVerifier = getLocalData(localConstants.codeVerifier);
    await getToken({
      code: code ? code : '',
      code_verifier: codeVerifier ? codeVerifier : '',
    });
  };

  React.useEffect(() => {
    storeLocalData({
      key: localConstants.code,
      value: code!,
    });
    checkHandle && handleToken();
  }, []);

  React.useEffect(() => {
    if (tokenData.isSuccess) {
      var expirationDate = addSeconds(tokenData.data.expires_in);
      var user: { email: string } = jwt(tokenData.data.id_token);
      storeSessionData(
        {
          key: sessionConstants.token,
          value: tokenData.data.access_token,
        },
        {
          key: sessionConstants.expiry,
          value: expirationDate.toString(),
        },
        {
          key: sessionConstants.idToken,
          value: tokenData.data.id_token,
        },
        {
          key: sessionConstants.refreshToken,
          value: tokenData.data.refresh_token,
        },
        {
          key: sessionConstants.email,
          value: user.email,
        }
      );
      clearLocalData();
      setToken(tokenData.data.access_token);
    } else if (tokenData.isError) {
      navigate(`/${routerPath.PUBLIC_ROUTE.errorUrlPath}`);
    }
  }, [tokenData]);

  return (
    <div>
      {token ? (
        <>
          <InitLogger />
          <Navigate to="/"></Navigate>
        </>
      ) : (
        <CenteredSpinnerCircle loadingText="Logging In"></CenteredSpinnerCircle>
      )}
    </div>
  );
};

import { Button } from '@trimbleinc/modus-react-bootstrap';
import React from 'react';
import { Download } from 'react-bootstrap-icons';
import { IPropTypesDownloadButton } from 'types/DownloadButtonTypes';
export const DownloadButton = (props: IPropTypesDownloadButton) => {
  return (
    <Button
      variant="outline-secondary"
      className={
        props.selectedRows.length != 0
          ? 'mx-1 mb-3 mx-sm-1'
          : 'disabled mx-1 mb-3 mx-sm-1'
      }
      onClick={() => {
        props.handleDownloadBtnClick();
      }}
    >
      <Download className="downloadIcon" />
      <span className="ml-3">Download</span>
    </Button>
  );
};

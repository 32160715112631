import React from 'react';
import { MessageSeverity } from 'types/enum/MessageSeverity';
import { FormField } from 'components/common/FormField';
import { ProjectStatusOption } from 'types/enum/ProjectEnum';
import { IPropTypesRouteValidations } from 'types/RouteValidationsTypes';
import WithRouteValidation from 'components/HOC/WithRouteValidation';
import { messages } from 'components/common/messages';
import WithToast from 'components/HOC/WithToast';
import { IPropTypesToast } from 'types/ToastTypes';

const ProjectStatus = (props: IPropTypesToast & IPropTypesRouteValidations) => {
  React.useEffect(() => {
    if (props.projectStatus == ProjectStatusOption.UNKNOWN) {
      props.handleSetToastProps(messages.FAILURE, MessageSeverity.error);
    } else if (props.projectStatus === ProjectStatusOption.RUNNING) {
      props.handleSetToastProps(
        `Project Status: ${props.projectStatus}`,
        MessageSeverity.warning
      );
    } else if (props.projectStatus !== ProjectStatusOption.SUCCEEDED) {
      props.handleSetToastProps(
        `Project Status: ${props.projectStatus}`,
        MessageSeverity.error
      );
    }
  }, [props.projectStatus]);

  return (
    <div>
      {props.projectStatus &&
        props.projectStatus != ProjectStatusOption.UNKNOWN && (
          <FormField
            disabled={true}
            type="text"
            maxLength={64}
            value={props.projectStatus}
            label="Project Status"
            controlId="projectStatus"
          ></FormField>
        )}
    </div>
  );
};

const ProjectStatusWithToast = WithToast(ProjectStatus);
const ProjectStatustWithRouteValidation = WithRouteValidation(
  ProjectStatusWithToast
);
export default ProjectStatustWithRouteValidation;

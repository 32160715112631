import React from 'react';
import { clearSessionData, sessionConstants } from '../storage/sessionStorage';
import { useRevokeRefreshTokenMutation } from '../../services/project/projectSlice';
import { useNavigate } from 'react-router-dom';
import routerPath from '../../config/routerPath';
import { SpinnerCircle } from '../common/SpinnerCircle';
import { AlertTypes } from '../../types/AlertTypes';
import { messages } from '../common/messages';
import { MessageSeverity } from '../../types/enum/MessageSeverity';
import { AlertBox } from '../common/AlertBox';
export const Logout = () => {
  const Styles = {
    container: {
      height: '100vh',
    },
  };
  const navigate = useNavigate();
  const [alert, setAlert] = React.useState<AlertTypes>();
  let handleCheck = true;
  const [revokeRefreshToken, revokeRefreshTokenData] =
    useRevokeRefreshTokenMutation();
  React.useEffect(() => {
    handleCheck &&
      revokeRefreshToken(
        sessionStorage.getItem(sessionConstants.refreshToken)!
      );
    handleCheck = false;
  }, []);
  React.useEffect(() => {
    if (revokeRefreshTokenData.isSuccess) {
      clearSessionData();
      navigate(routerPath.PRIVATE_ROUTE.projectsHomePath);
    } else if (revokeRefreshTokenData.isError) {
      console.log('error');
      setAlert({
        message: messages.FAILURE,
        severity: MessageSeverity.error,
      });
    }
  }, [revokeRefreshTokenData]);
  return revokeRefreshTokenData.isLoading ? (
    <div
      className="d-flex align-items-center justify-content-center w-100"
      style={Styles.container}
    >
      <SpinnerCircle loadingText="Loading" />
    </div>
  ) : (
    <div>
      {revokeRefreshTokenData.isError && (
        <div className="p-4">
          <AlertBox
            alert={{
              message: alert?.message!,
              severity: alert?.severity!,
            }}
            dismissble={false}
          ></AlertBox>
        </div>
      )}
    </div>
  );
};

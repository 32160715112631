import { StorageTypes } from 'types/StorageTypes';
export const storeSessionData = (...data: StorageTypes[]) => {
  for (let i = 0; i < data.length; i++) {
    sessionStorage.setItem(data[i].key, data[i].value);
  }
};

export const getSessionData = (key: string) => {
  return sessionStorage.getItem(key);
};

export const clearSessionData = () => {
  sessionStorage.clear();
};

export class sessionConstants {
  public static readonly token = 'token';
  public static readonly idToken = 'id.token';
  public static readonly expiry = 'expirationDate';
  public static readonly refreshToken = 'refreshToken';
  public static readonly email = 'email';
}

export enum RuleType {
  COUNT = 'count',
  DURATIONINDAYS = 'duration',
}

export enum RuleCondition {
  ONCEEVERY = 'once every',
}

export enum TriggerPointType {
  IN_DAYS = 'in days',
}

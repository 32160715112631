import React from 'react';
import { Button, Modal } from '@trimbleinc/modus-react-bootstrap';
import { IPropTypesConfigurationsModal } from 'types/ConfigurationsModalTypes';

export const ConfigurationsModal = (props: IPropTypesConfigurationsModal) => {
  const {
    invalidModalName,
    invalidModalMsg,
    handleInvalidModalName,
    handleInvalidModalMsg,
  } = props;
  return (
    <Modal
      show={invalidModalName ? true : false}
      centered
      size="lg"
      onHide={() => {
        handleInvalidModalName('');
        handleInvalidModalMsg('');
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{invalidModalName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {invalidModalMsg
            ? invalidModalMsg
            : `Incomplete values for ${invalidModalName} configuration.`}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => handleInvalidModalName('')}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

import {
  Button,
  OverlayTrigger,
  Tooltip,
} from '@trimbleinc/modus-react-bootstrap';
import React from 'react';
import { Form } from 'react-bootstrap';
import { FormCheckType } from 'react-bootstrap/esm/FormCheck';
interface input {
  id: string;
  name?: string;
  checked?: boolean;
}
interface FormRadioButtonProps {
  value?: string;
  Id: string;
  label: string;
  handleInput(data: input): void;
  disabled?: boolean;
  option: string[];
  className?: string;
  removeLabel?: boolean;
  type?: FormCheckType;
  tooltipRequired?: boolean;
  tooltipText?: string;
}

const Styles = {
  formLabel: {
    fontSize: '14px',
  },
  formContent: {
    fontSize: '16px',
    display: 'inline',
  },
};

export const FormRadioButton = (props: FormRadioButtonProps) => {
  return (
    <div>
      <Form.Group className={props.className ? props.className : ' mt-2 mb-4'}>
        {props.removeLabel
          ? props.removeLabel
          : true && (
            <Form.Label
              className=" text-secondary mr-4 m-0 p-0"
              style={Styles.formLabel}
            >
              {props.label}
              {props.tooltipRequired && (
                <OverlayTrigger
                  key="overlay1"
                  placement="top"
                  overlay={
                    <Tooltip id="toptooltip">{props.tooltipText}</Tooltip>
                  }
                >
                  <Button
                    variant="icon-only"
                    size="sm"
                    className="btn-text-secondary mb-1 mx-1"
                  >
                    <i className="modus-icons">help</i>
                  </Button>
                </OverlayTrigger>
              )}
            </Form.Label>
          )}
        {props.option.map((value) => {
          return (
            <div style={{ display: 'inline' }} key={value}>
              <Form.Check
                disabled={props.disabled}
                inline
                type={props.type ? props.type : 'radio'}
                id={value}
                checked={props.value === value}
                name={props.Id}
                className={props.removeLabel ? undefined : 'mr-1'}
                onChange={(event) => {
                  props.handleInput({
                    id: (event.target as HTMLInputElement).id,
                    name: (event.target as HTMLInputElement).name,
                    checked: (event.target as HTMLInputElement).checked,
                  });
                }}
              />
              {!props.removeLabel && (
                <Form.Label
                  style={Styles.formContent}
                  className={
                    props.disabled ? 'mr-3 font-weight-bold p-0' : 'mr-3 p-0'
                  }
                >
                  {value}
                </Form.Label>
              )}
            </div>
          );
        })}
      </Form.Group>
    </div>
  );
};

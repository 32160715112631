import { Button } from '@trimbleinc/modus-react-bootstrap';
import React from 'react';
import { IPropTypesConfigurationsRightPanel } from 'types/ConfigurationsRightPanelTypes';
import { NPSForm } from './NPSForm';
import { PIIForm } from './PIIForm';

export const RightPanel = (props: IPropTypesConfigurationsRightPanel) => {
  const {
    showComponents,
    showNPS,
    showPII,
    NPSEnabledValue,
    NPSFormValues,
    PIIFormValues,
    handleNPSFormValues,
    handlePIIFormValues,
    removeFieldsNPS,
    removeFieldsPII,
    addFieldsNPS,
    addFieldsPII,
    handleSetToastProps,
  } = props;
  return (
    <div
      className={
        showComponents
          ? 'col-md-8 col-10 py-3 ml-3 ml-md-0 py-md-0 mt-sm-2 mt-md-0  rounded-5'
          : 'col-10 py-3'
      }
      style={{
        position: 'relative',
      }}
    >
      <div
        className="RightPanel"
        style={{
          maxHeight: '60vh',
          overflowY: 'auto',
        }}
      >
        <div className="NPSForm mt-5">
          <>
            {NPSEnabledValue === true && showNPS === true && (
              <NPSForm
                NPSFormValues={NPSFormValues}
                handleNPSFormValues={handleNPSFormValues}
                removeFieldsNPS={removeFieldsNPS}
                handleSetToastProps={handleSetToastProps}
              />
            )}
          </>
        </div>
        {NPSEnabledValue === true && showNPS === true && (
          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="outline-primary"
              className=""
              onClick={() => {
                if (showNPS) {
                  addFieldsNPS({
                    eventName: '',
                    ruleType: 'count',
                    ruleCondition: 'once every',
                    triggerPoint: '1',
                  });
                }
              }}
            >
              Add New NPS Config
            </Button>
          </div>
        )}
        {NPSEnabledValue === false && showNPS === true && (
          <div className="d-flex justify-content-center mt-2">
            <div style={
            {
             border: '2px solid black',
             padding:"5px"
            }
            }>
              Enable the NPS configuration
            </div>  
          </div>
        )}
        <div className="PIIForm mt-5">
          <>
            {showPII === true && (
              <PIIForm
                PIIFormValues={PIIFormValues}
                handlePIIFormValues={handlePIIFormValues}
                removeFieldsPII={removeFieldsPII}
              />
            )}
          </>
        </div>
        {showPII === true && (
          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="outline-primary"
              className=""
              onClick={() => {
                if (showPII) {
                  addFieldsPII({
                    attributeName: '',
                    pii_action: 'hash',
                  });
                }
              }}
            >
              Add New PII Config
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

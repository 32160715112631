import React, { ReactNode } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
interface input {
  id: string;
  value: string;
}
export interface FormFieldProps {
  value: string;
  controlId: string;
  placeholderText?: string;
  label: string;
  error?: string;
  disabled: boolean;
  handleInput?(data: input): void;
  type: string;
  children?: ReactNode;
  showPass?: boolean;
  maxLength?: number;
  className?: string;
}

const Styles = {
  formLabel: {
    fontSize: '14px',
  },
  formContent: {
    fontSize: '16px',
    height: '49px',
  },
};

export const FormField = (props: FormFieldProps) => {
  return (
    <div>
      <Form.Group
        className={props.className ? props.className : 'mb-4'}
        controlId={props.controlId}
      >
        <Form.Label className=" text-secondary" style={Styles.formLabel}>
          {props.label}
        </Form.Label>

        <InputGroup>
          <Form.Control
            disabled={props.disabled}
            plaintext={props.disabled}
            style={Styles.formContent}
            maxLength={props.maxLength}
            className={
              props.disabled
                ? props.error
                  ? 'is-invalid m-0'
                  : 'font-weight-bold m-0'
                : props.error
                ? 'is-invalid m-0'
                : undefined
            }
            required
            value={props.value}
            type={props.showPass ? 'text' : props.type}
            placeholder={props.placeholderText}
            size="lg"
            onInput={(event) => {
              props.handleInput &&
                props.handleInput({
                  id: (event.target as HTMLInputElement).id,
                  value: (event.target as HTMLInputElement).value,
                });
            }}
          ></Form.Control>
          {props.children}
        </InputGroup>

        {props.error && (
          <Form.Text className="text-danger">{props.error}</Form.Text>
        )}
      </Form.Group>
    </div>
  );
};

import React, { useEffect } from 'react';
import { ProjectCard } from 'components/Projects/ProjectCard';
import { useNavigate } from 'react-router-dom';
import { CenteredSpinnerCircle } from 'components/common/CenteredSpinnerCircle';
import { Container, Row, Col, Button } from 'react-bootstrap';
import routerPath from 'config/routerPath';
import {
  useDeleteProjectMutation,
  useGetProjectsQuery,
} from 'services/project/projectSlice';
import { messages } from 'components/common/messages';
import { ToastBox } from 'components/common/ToastBox';
import { MessageSeverity } from 'types/enum/MessageSeverity';
import { IPropTypesToast } from 'types/ToastTypes';
import WithToast from 'components/HOC/WithToast';
import { sendCustomLog } from 'helpers/sdkCustomEventLogger';
import { ConfirmationDialog } from 'components/common/ConfirmationDialog';
import { useGetUserQuotaQuery } from 'services/users/userSlice';
import { checkValidEmailDomain } from 'common/commonFunction';
const Styles = {
  btn: {
    height: '45px',
    backgroundColor: '#d3d3d3',
  },
  innerContainer: {
    height: '78vh',
    overflow: 'auto',
  },
  outerContainer: {
    height: '90vh',
  },
};

const Home = (props: IPropTypesToast) => {
  const { isSuccess, data, isFetching, isError, error } = useGetProjectsQuery();
  const {
    data: quotaData,
    isError: quotaError,
    refetch,
  } = useGetUserQuotaQuery();
  useEffect(() => {
    refetch();
  }, [data]);
  const availableQuota = quotaData?.availableProjectQuota;
  const [deleteProject, deleteProjectData] = useDeleteProjectMutation();
  const navigate = useNavigate();
  const [deleteProjectName, setDeleteProjectName] = React.useState('');
  const [deleteProjectDetails, setDeleteProjectDetails] = React.useState('');
  const handleFetch = (value: string, projectName: string) => {
    setDeleteProjectDetails(value);
    setDeleteProjectName(projectName);
  };

  React.useEffect(() => {
    if (deleteProjectData.isSuccess) {
      props.handleSetToastProps(
        messages.DELETION_INPROGRESS,
        MessageSeverity.warning
      );
    } else if (deleteProjectData.isError) {
      props.handleSetToastProps(messages.FAILURE, MessageSeverity.error);
    }
  }, [deleteProjectData.isLoading, deleteProjectData.isSuccess]);
  useEffect(() => {
    if (isError && error && 'data' in error) {
      if (error.status >= 400 && error.status <= 499) {
        props.handleSetToastProps(error.data.error, MessageSeverity.error);
      }
      if (error.status >= 500) {
        props.handleSetToastProps(
          messages.INTERNAL_SERVER_ERROR,
          MessageSeverity.error
        );
      }
    }
  }, [isError]);
  return !deleteProjectData.isLoading ? (
    !isFetching ? (
      <Container fluid style={Styles.outerContainer}>
        {deleteProjectDetails && (
          <ConfirmationDialog
            dialogHeader="Delete Confirmation"
            dialogContent={`Do you want to delete ${deleteProjectName}`}
            onNo={() => {
              setDeleteProjectDetails('');
            }}
            onYes={() => {
              deleteProject({
                projectId: deleteProjectDetails,
              });
              setDeleteProjectDetails('');
            }}
          ></ConfirmationDialog>
        )}
        <Row className="mt-2 mb-4">
          <Col>
            <h1 className="h1 font-weight-bold mt-2 col-md-2 col-xs-12">
              Projects
            </h1>
          </Col>
          <Col>
            <Button
              className=" rounded shadow border-primary float-right m-0"
              variant="light"
              style={
                !availableQuota || !checkValidEmailDomain()
                  ? Styles.btn
                  : { height: '45px' }
              }
              onClick={() => {
                if (quotaError) {
                  props.handleSetToastProps(
                    messages.QUOTA_ERROR,
                    MessageSeverity.warning
                  );
                } else {
                  if (availableQuota !== 0 && checkValidEmailDomain()) {
                    sendCustomLog('CREATE_PROJECT_BUTTON', 'PROJECT', {
                      message: 'Clicked on create project button from home',
                    });
                    navigate(routerPath.PRIVATE_ROUTE.createProjectPath);
                  } else if (availableQuota === 0) {
                    props.handleSetToastProps(
                      messages.QUOTA_OVER,
                      MessageSeverity.warning
                    );
                  } else {
                    props.handleSetToastProps(
                      messages.INVALID_EMAIL_DOMAIN,
                      MessageSeverity.warning
                    );
                  }
                }
              }}
            >
              Create Project
            </Button>
          </Col>
        </Row>
        <Row style={Styles.innerContainer}>
          {isSuccess ? (
            data.length > 0 ? (
              data.map((card) => {
                return (
                  <Col
                    xs="12"
                    sm="6"
                    md="6"
                    lg="4"
                    xl="3"
                    className="d-flex justify-content-center"
                    key={card.id}
                  >
                    <ProjectCard
                      handleSetToastProps={props.handleSetToastProps}
                      handleFetch={handleFetch}
                      {...card}
                    ></ProjectCard>
                  </Col>
                );
              })
            ) : (
              <div className="col-12 d-flex justify-content-center nullFilesCustom">
                <div className="container d-flex justify-content-center align-items-center">
                  <span className="display-3 font-weight-light">
                    No Projects
                  </span>
                </div>
              </div>
            )
          ) : (
            <Container>
              <ToastBox
                alert={{
                  message: messages.FAILURE,
                  severity: MessageSeverity.error,
                }}
              ></ToastBox>
            </Container>
          )}
        </Row>
      </Container>
    ) : (
      <CenteredSpinnerCircle loadingText="Loading Projects"></CenteredSpinnerCircle>
    )
  ) : (
    <CenteredSpinnerCircle loadingText="Deleting...."></CenteredSpinnerCircle>
  );
};

const HomeWithToast = WithToast(Home);

export default HomeWithToast;

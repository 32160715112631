import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

import config from 'config';
import path from 'config/urlPath';
import { ProjectTypes } from 'types/ProjectTypes';
import { projectStatusTypes } from 'types/ProjectStatusTypes';
import { CustomError } from 'types/CustomErrorTypes';
import { setHeaders } from 'services/apiHeaders';
import { GetProjectTypes } from 'types/GetProjectTypes';

export const projectSlice = createApi({
  reducerPath: 'projectSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: config.TRIMBLE_INSIGHTS_PROJECTS_SERVICE.BASE_URL,
    prepareHeaders(headers) {
      return setHeaders(headers);
    },
  }) as BaseQueryFn<string | FetchArgs, unknown, CustomError, {}>,
  tagTypes: ['project'],
  endpoints: (builder) => ({
    getProjects: builder.query<GetProjectTypes[], void>({
      query() {
        return path.TRIMBLE_INSIGHTS_PROJECTS_SERVICE_URL.projects;
      },
      transformResponse: (res: GetProjectTypes[]) =>
        res?.sort((a, b) => {
          if (a.displayName < b.displayName) return -1;
          if (a.displayName > b.displayName) return 1;
          return 0;
        }),
      providesTags: ['project'],
    }),
    getProjectStatus: builder.query<projectStatusTypes, string>({
      query(data) {
        return path.TRIMBLE_INSIGHTS_PROJECTS_SERVICE_URL.projectStatus.replace(
          '{id}',
          data
        );
      },
      providesTags: ['project'],
    }),
    createProject: builder.mutation<any, ProjectTypes>({
      query: (data) => ({
        url: path.TRIMBLE_INSIGHTS_PROJECTS_SERVICE_URL.projects,
        method: 'POST',
        body: {
          name: data.name,
          displayName: data.displayName,
          description: data.description,
          platform: data.platform,
          language: data.language,
          languageVersion: data.languageVersion,
          division: data.division,
          useSDK: data.useSDK,
          trackDefaultEvents: data.trackDefaultEvents,
        },
      }),
      invalidatesTags: ['project'],
    }),
    revokeRefreshToken: builder.mutation<any, string>({
      query: (data) => ({
        url: path.TRIMBLE_INSIGHTS_PROJECTS_SERVICE_URL.revoke,
        method: 'POST',
        body: {
          RefreshToken: data,
        },
      }),
    }),
    deleteProject: builder.mutation<any, any>({
      query: (data) => ({
        url: path.TRIMBLE_INSIGHTS_PROJECTS_SERVICE_URL.projectDetails.replace(
          '{id}',
          data.projectId
        ),
        method: 'DELETE',
        body: data,
      }),
      invalidatesTags: ['project'],
    }),
    getProjectDetails: builder.query<any, any>({
      query: (projectId) => ({
        url: path.TRIMBLE_INSIGHTS_PROJECTS_SERVICE_URL.projectDetails.replace(
          '{id}',
          projectId
        ),
        method: 'GET',

      }),
      providesTags: ['project'],
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useCreateProjectMutation,
  useRevokeRefreshTokenMutation,
  useGetProjectStatusQuery,
  useDeleteProjectMutation,
  useGetProjectDetailsQuery,
} = projectSlice;

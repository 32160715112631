import {
  getSessionData,
  sessionConstants,
} from 'components/storage/sessionStorage';
import { APIHeaderNames } from 'types/enum/ApiHeadersEnum';

export const setHeaders = (headers: any, sliceName?: string) => {
  if (sliceName == 'authTokenSlice') {
    headers.set(
      APIHeaderNames.CONTENT_TYPE,
      'application/x-www-form-urlencoded'
    );
    return headers;
  }
  headers.set(APIHeaderNames.CONTENT_TYPE, 'application/json');
  headers.set(
    APIHeaderNames.AUTHORIZATION,
    `Bearer ${getSessionData(sessionConstants.token)}`
  );
  return headers;
};

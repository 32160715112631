import React from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ProjectTypeOption } from 'types/enum/ProjectEnum';
import routerPath from 'config/routerPath';
import { FaEllipsisV, FaTrash } from 'react-icons/fa';
import { MessageSeverity } from 'types/enum/MessageSeverity';
import { messages } from 'components/common/messages';
import 'styles/ProjectCard.scss';


interface ProjectDataTypes {
  name: string;
  id: string;
  displayName: string;
  useSDK: boolean;
  trackDefaultEvents: boolean;
  division: string;
  platform: string;
  description: string;
  language: string;
  languageVersion: string;
  consumerKey: string;
  consumerSecret: string;
  applicationId: string;
  languageOthers: string;
  deleteStatus: string | undefined,
  handleFetch: (value: string, projectName: string) => void;
  handleSetToastProps: (message: string, severity: string) => void;
}

export const ProjectCard = (props: ProjectDataTypes) => {
  const [hover, setHover] = React.useState(false);
  const deletionStatus = props.deleteStatus == "InProgress" || props.deleteStatus == "Failed";
  const navigate = useNavigate();
  let imgsrc: string;
  switch (props.platform) {
    case ProjectTypeOption.ANDROID:
      imgsrc = 'images/android.jpg';
      break;
    case ProjectTypeOption.IOS:
      imgsrc = 'images/ios.jpg';
      break;
    case ProjectTypeOption.WEB:
      imgsrc = 'images/web.jpg';
      break;
    case ProjectTypeOption.DESKTOP:
      imgsrc = 'images/desktop.jpg';
      break;
    case ProjectTypeOption.XAMARIN:
      imgsrc = 'images/xamarin.png';
      break;
    case ProjectTypeOption.XR:
      imgsrc = 'images/xr.png';
      break;
    default:
      imgsrc = 'images/others.jpeg';
      break;
  }

  return (
    <Card
      key={props.name}
      className={
        !deletionStatus ? (
          hover
            ? 'cardContainer mb-4 rouded shadow-lg justify-content-between text-center cardContainerHover'
            : 'mb-4 rouded shadow-sm justify-content-between text-center cardContainer')
          : 'AfterDeletion mb-4 rouded shadow-sm justify-content-between text-center cardContainer'
      }
      onClick={() => {
        if (props.deleteStatus == "InProgress") {
          props.handleSetToastProps(
            messages.DELETION_INPROGRESS,
            MessageSeverity.warning
          );

        }
        else if (props.deleteStatus == "Failed") {
          props.handleSetToastProps(
            messages.DELETION_FAILED,
            MessageSeverity.error
          );
        }
        else {
          navigate(`${routerPath.PRIVATE_ROUTE.projectPath}/${props.id}`);
        }
      }}
      onMouseEnter={() => {
        if (!deletionStatus)
          setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      <Card.Header bsPrefix="p-2">
        <img
          className="card-img-top"
          src={imgsrc}
          alt={props.platform}
          width="260px"
          height="150px"
        ></img>
      </Card.Header>
      <Card.Title bsPrefix="pl-2 pr-2 mt-n2" className='cardTitle'>
        <h3>{props.displayName}</h3>
      </Card.Title>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Card.Subtitle bsPrefix="pl-2 pr-2" className='cardContent'>
          <h4 className="text-muted mb-2" >
            {props.division}
          </h4>
          <p className=" font-weight-normal">
            {props.platform}
          </p>
        </Card.Subtitle>
        <Dropdown
          style={{ padding: '8px 14px 0px 30px' }}
          onClick={e => e.stopPropagation()}
        >
          <Dropdown.Toggle disabled={deletionStatus} variant="light" bsPrefix="custom-btn" >
            <FaEllipsisV />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => {
              props.handleFetch(props.id, props.displayName)
            }}><FaTrash /> Delete</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

    </Card>
  );
};


